import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'

// src
import { userIsOnline } from '../../../SendBird/action'
import { useNavigate } from 'react-router-dom'
import Avatar from '../../../../../components/Avatar/Avatar'

const HeaderChat = ({ sendBird, user2 }) => {
  // hooks
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  // url
  const href = document.location.href.split('/')
  const userId = href[href.length - 1]

  // state
  const [isOnline, setIsOnline] = useState([])
  useEffect(() => {
    if (user2) userIsOnline(sendBird, [user2?.sendbird_user_id]).then(value => setIsOnline(value))
  }, [user2])
  return (
        <div className="chat-header">
            <ArrowBackOutlinedIcon className="cursor-pointer arrow" onClick={() => navigate(-1)}/>
            <Avatar id={userId}
                    url={user2?.avatar?.download_url}
                    status_online={isOnline.find((value) => value?.userId === userId)}
                    name={user2?.full_name}/>

            <div className="user-detail-chat">

                <h4 className='user-name-near-avatar'
                >{user2?.full_name}
                    {isOnline[0] !== 'false' && isOnline?.length >= 1 && <p className="user-online user-status"><p
                        className="status-circle status-circle-online"/>{t('pages.inboxpage.chats.online')}
                    </p>}
                </h4>

                <p className='userEmail'>{user2?.job_title}</p>
            </div>
        </div>
  )
}

export default HeaderChat

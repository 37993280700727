import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { Tooltip } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// src
import './SideBar.scss'
import colors from '../../Colors.scss'
// import ArrowPlayer from '../../SVG/global/ArrowPlayer'
// import ParticipantsIcon from '../../SVG/custom/ParticipantsIcon'
// import Hotline from '../../SVG/pages/Chats/Hotline'
// import QuestionsIcon from '../../SVG/global/QuestionsIcons'
// import PollIcon from '../../SVG/global/PollIcon'
// import ChatIcon from '../../SVG/global/ChatIcon'
// custom SFS
import ArrowPlayer from '../../SVG/custom/ArrowPlayer'
import ParticipantsIcon from '../../SVG/custom/ParticipantsIcon'
import Hotline from '../../SVG/custom/Hotline'
import QuestionsIcon from '../../SVG/custom/QuestionsIcon'
import PollIcon from '../../SVG/custom/PollIcon'
import ChatIcon from '../../SVG/custom/ChatIcon'
import AgendaIcon from '../../SVG/custom/AgendaIcon'
import { addMenu, changeShowLiveNow } from '../../redux/slices/globalValue/globalValue'
import { useGetUserAccessQuery } from '../../redux/api/userApi/userApi'

const SideBar = (props) => {
  // hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  // url
  const url = location.pathname.split('/')
  const showMenu = location.pathname.includes('/stream') ? true : props.showMenu
  const action = url.includes('chat') ? 'inbox' : url[4]
  const id = url[url.length - 1]

  // redux
  const eventId = useSelector(state => state.globalValueReducer.eventId)
  const slug = useSelector(state => state.globalValueReducer.slug)
  const unreadMsgCount = useSelector(state => state.sendBirdSliceReducer.unreadMsgCount)
  // state
  const [selected, setSelected] = useState(props.selectedprop)

  const onChange = props.onChange
  const { data: userAccess, refetch } = useGetUserAccessQuery(eventId, { skip: !eventId })

  // console.log('slug', slug)

  const {
    stream_player_enabled,
    agenda_enabled,
    participants_enabled,
    chat_enabled,
    polls_enabled,
    questions_enabled,
    posters_enabled,
    exhibition_enabled,
    hotline_enabled,
    media_library_enabled,
    live_now_enabled
  } = userAccess?.event_features || {}

  const { t, i18n } = useTranslation()

  useEffect(() => {
    refetch()
  }, [eventId])

  useEffect(() => {
    if (userAccess?.event_features?.live_now_enabled !== null && userAccess?.event_features?.live_now_enabled !== undefined) {
      dispatch(changeShowLiveNow(userAccess?.event_features?.live_now_enabled))
    }
  }, [userAccess])

  useEffect(() => {
    const permissions = userAccess?.event_features

    if (permissions) {
      const availablePages = {
        stream_player_enabled: `/events/${eventId}/${slug}/stream/`,
        agenda_enabled: `/events/${eventId}/${slug}/agenda`,
        participants_enabled: `/events/${eventId}/${slug}/participants/`,
        chat_enabled: `/events/${eventId}/${slug}/inbox/`,
        polls_enabled: `/events/${eventId}/${slug}/polls/`,
        questions_enabled: `/events/${eventId}/${slug}/questions/`,
        hotline_enabled: `/events/${eventId}/${slug}/hotline/`,
        posters_enabled: `/events/${eventId}/${slug}/posters/`,
        exhibition_enabled: `/events/${eventId}/${slug}/exhibition/`,
        media_library_enabled: `/events/${eventId}/${slug}/downloads/`
      }

      const firstAvailablePage = Object.keys(permissions).find(key => {
        return permissions[key] && availablePages[key]
      })

      const checkAnyPages = () => {
        const availablePagesList = Object.keys(permissions).map(key => {
          return permissions[key] && availablePages[key] ? key : null
        }).filter(Boolean).map(el => availablePages[el])

        console.log('availablePagesList', availablePagesList)

        const currentPage = availablePagesList.find(el => window.location.pathname.includes(el))

        console.log('currentPage', currentPage)

        return !currentPage
      }

      if (firstAvailablePage && !window.location.pathname.includes(availablePages[firstAvailablePage]) && checkAnyPages()) {
        navigate(availablePages[firstAvailablePage])
        onChange(true)
      }
    }
  }, [userAccess?.event_features])

  const handleChange = (to) => {
    props.onChange(true)
    navigate(to)
  }

  useEffect(() => {
    if (selected !== action) {
      setSelected(action)
    }
    if (selected === 'stream' && action === 'stream') {
      setSelected(action)
    }
  }, [selected, action])

  return (
        <div className="sidebar-container"
             style={{
               minWidth: showMenu ? '78px' : '100%',
               maxWidth: showMenu ? isMobile ? '' : '78px' : 'auto'
             }}
        >
            {/* / STREAM/ */}
            {stream_player_enabled &&
                <Tooltip title={t('sidebar.stream')}>
                    <div
                        className={`sidebar-icon ${selected === 'stream' && action === 'stream' && showMenu && 'border-side-bar'}`}
                        onClick={() => {
                          if (!url.includes('stream')) {
                            handleChange(`/events/${eventId}/${slug}/stream/`)
                            dispatch(addMenu('stream'))
                            setSelected('stream')
                          }
                        }}
                    >
                        <ArrowPlayer opacity='1' color={selected === 'stream' && action === 'stream' && showMenu
                          ? colors.color_selected_icon
                          : colors.color_sidebar_icon} width={30} height={30} colorArrow={colors.color_white}/>
                        {selected === 'stream' &&
                            <p className="sidebar-icon-text">{t('sidebar.stream')}</p>}
                    </div>
                </Tooltip>}
            {/*  AGENDA */}
            {agenda_enabled && <Tooltip title={t('sidebar.agenda')}>
                <div
                    className={`sidebar-icon ${selected === 'agenda' && action === 'agenda' && showMenu && 'border-side-bar'}`}
                    onClick={() => {
                      handleChange(`/events/${eventId}/${slug}/agenda/`)
                      dispatch(addMenu('agenda'))
                      setSelected('agenda')
                    }}
                >
                    <AgendaIcon opacity='1' color={
                        selected === 'agenda' && action === 'agenda' && showMenu
                          ? colors.color_selected_icon
                          : colors.color_sidebar_icon
                    } width={30} height={30}/>
                    {selected === 'agenda' &&
                        <p className="sidebar-icon-text">{t('sidebar.agenda')}</p>}
                </div>
            </Tooltip>}
            {/* PARTICIPANTS */}
            {participants_enabled &&
                <Tooltip title={t('sidebar.participants')}>
                    <div
                        className={`sidebar-icon ${selected === 'participants' && action === 'participants' && showMenu && 'border-side-bar'}`}
                        onClick={() => {
                          handleChange(`events/${eventId}/${slug}/participants/`)
                          dispatch(addMenu('participants'))
                          setSelected('participants')
                        }}
                    >
                        <ParticipantsIcon opacity='1' color={
                            selected === 'participants' &&
                            action === 'participants' &&
                            showMenu
                              ? colors.color_selected_icon
                              : colors.color_sidebar_icon
                        } width={30} height={30}/>
                        {selected === 'participants' && (
                            <p className="sidebar-icon-text">{t('sidebar.participants')}</p>
                        )}
                    </div>
                </Tooltip>}
            {/* CHAT */}
            {chat_enabled && <Tooltip title={t('sidebar.chat')}>
                <div
                    className={`sidebar-icon ${selected === 'inbox' && action === 'inbox' && showMenu && 'border-side-bar'}`}
                    onClick={() => {
                      handleChange(`/events/${eventId}/${slug}/inbox/`)
                      dispatch(addMenu('inbox'))

                      setSelected('inbox')
                    }}
                >
                    <span className="unread__div">{unreadMsgCount || ''}</span>
                    <ChatIcon color={selected === 'inbox' && action === 'inbox' && showMenu
                      ? colors.color_selected_icon
                      : colors.color_sidebar_icon}/>

                    {selected === 'inbox' &&
                        <p className="sidebar-icon-text">{t('sidebar.chat')}</p>}
                </div>
            </Tooltip>}

            {/* POLL */}
            {polls_enabled && <Tooltip title={t('sidebar.polls')}>
                <div
                    className={`sidebar-icon ${selected === 'polls' && action === 'polls' && showMenu && 'border-side-bar'}`}
                    onClick={() => {
                      handleChange(`/events/${eventId}/${slug}/polls/`)
                      dispatch(addMenu('polls'))
                      setSelected('polls')
                    }}
                >
                    <PollIcon color={selected === 'polls' && action === 'polls' && showMenu
                      ? colors.color_selected_icon
                      : colors.color_sidebar_icon}/>
                    {selected === 'polls' &&
                        <p className="sidebar-icon-text">{t('sidebar.polls')}</p>}
                </div>
            </Tooltip>}

            {/* QUESTIONS */}
            {questions_enabled &&
                <Tooltip title={t('sidebar.questions')}>
                    <div
                        className={`sidebar-icon ${selected === 'questions' && action === 'questions' && showMenu && 'border-side-bar'}`}
                        onClick={() => {
                          handleChange(`/events/${eventId}/${slug}/questions/`)
                          dispatch(addMenu('questions'))
                          setSelected('questions')
                        }}
                    >
                        <QuestionsIcon color={selected === 'questions' && action === 'questions' && showMenu
                          ? colors.color_selected_icon
                          : colors.color_sidebar_icon}/>
                        {selected === 'questions' && (
                            <p className="sidebar-icon-text">{t('sidebar.questions')}</p>
                        )}
                    </div>
                </Tooltip>}

            {media_library_enabled && <Tooltip title={'Downloads'}>
                <div
                    className={`sidebar-icon ${selected === 'attachments' && action === 'attachments' && showMenu && 'border-side-bar'}`}
                    onClick={() => {
                      handleChange(`/events/${eventId}/${slug}/attachments/`)
                      dispatch(addMenu('attachments'))
                      setSelected('attachments')
                    }}
                >
                    <img src='/download-icon.svg'/>
                    {selected === 'attachments' && (
                        <p className="sidebar-icon-text">{t('sidebar.downloads')}</p>
                    )}
                </div>
            </Tooltip>}

            {/* POSTERS */}
            {posters_enabled && <Tooltip title={t('sidebar.posters')}>
                <div
                    className={`sidebar-icon ${selected === 'posters' && action === 'posters' && showMenu && 'border-side-bar'} `}
                    onClick={() => {
                      handleChange(`events/${eventId}/${slug}/posters/`)
                      dispatch(addMenu('posters'))
                      setSelected('posters')
                    }}
                >
                    <svg width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg"
                         fill={
                             selected === 'posters' &&
                             action === 'posters' &&
                             showMenu
                               ? colors.color_selected_icon
                               : colors.color_sidebar_icon
                         }>
                        <path
                            d="M13.8134 21.5485C14.1431 21.6252 14.1733 22.057 13.8522 22.164L11.9562 22.788C7.19221 24.324 4.68421 23.04 3.13621 18.276L1.60021 13.536C0.0642142 8.772 1.33621 6.252 6.10021 4.716L6.93221 4.44047C7.33507 4.30706 7.72422 4.71 7.60463 5.11718C7.51513 5.42193 7.43042 5.74421 7.34821 6.084L6.17221 11.112C4.85221 16.764 6.78421 19.884 12.4362 21.228L13.8134 21.5485Z"/>
                        <path
                            d="M19.2039 1.45206L17.1999 0.984059C13.1919 0.0360586 10.8039 0.816058 9.39987 3.72006C9.03987 4.45206 8.75187 5.34006 8.51187 6.36006L7.33587 11.3881C6.15987 16.4041 7.70787 18.8761 12.7119 20.0641L14.7279 20.5441C15.4239 20.7121 16.0719 20.8201 16.6719 20.8681C20.4159 21.2281 22.4079 19.4761 23.4159 15.1441L24.5919 10.1281C25.7679 5.11206 24.2319 2.62806 19.2039 1.45206ZM16.9479 13.5961C16.8399 14.0041 16.4799 14.2681 16.0719 14.2681C15.9999 14.2681 15.9279 14.2561 15.8439 14.2441L12.3519 13.3561C11.8719 13.2361 11.5839 12.7441 11.7039 12.2641C11.8239 11.7841 12.3159 11.4961 12.7959 11.6161L16.2879 12.5041C16.7799 12.6241 17.0679 13.1161 16.9479 13.5961ZM20.4639 9.54006C20.3559 9.94806 19.9959 10.2121 19.5879 10.2121C19.5159 10.2121 19.4439 10.2001 19.3599 10.1881L13.5399 8.71206C13.0599 8.59206 12.7719 8.10006 12.8919 7.62006C13.0119 7.14006 13.5039 6.85206 13.9839 6.97206L19.8039 8.44806C20.2959 8.55606 20.5839 9.04806 20.4639 9.54006Z"/>
                    </svg>
                    {selected === 'posters' && (
                        <p className="sidebar-icon-text">{t('sidebar.posters')}</p>
                    )}
                </div>
            </Tooltip>}

            {/* EXHIBITION   */}
            {exhibition_enabled && <Tooltip title={t('sidebar.sponsors-exhibitors')}>
                <div
                    className={`sidebar-icon ${selected === 'exhibition' && action === 'exhibition' && showMenu && 'border-side-bar'} `}
                    onClick={() => {
                      handleChange(`events/${eventId}/${slug}/exhibition/`)
                      dispatch(addMenu('exhibition'))
                      setSelected('exhibition')
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width={24}>

                        <path
                            fill={
                                selected === 'exhibition' &&
                                action === 'exhibition' &&
                                showMenu
                                  ? colors.color_selected_icon
                                  : colors.color_sidebar_icon
                            }
                            d="M15.25 8.172c-.061.067-.15.137-.25.207V2.5C15 1.122 13.879 0 12.5 0h-9A2.503 2.503 0 0 0 1 2.5v5.798c-.5-.295-.895-.502-.959-.333-.149.385.118.653.653 1.308s2.839 1.557 2.839 1.557v2.738c0 .804 1.663 2.023 2.524 2.023s1.514-.595 1.514-1.012V12.05c.12.081.301.13.505.159v2.37c0 .416.578 1.42 2.331 1.42 1.753 0 2.212-2.075 2.212-2.431v-2.529c1.247-.655 2.524-1.012 3.135-2.183.575-1.101.03-1.279-.504-.684zm-3.135 1.35H9.591c-.564 0-1.515.149-1.515.506v.327c-.049-.059-.076-.101-.076-.101-.415-.386-.831-.683-1.573-.653-.743.029-2.495-.09-3.505-.328-.271-.063-.593-.215-.922-.394V2.5C2 1.673 2.673 1 3.5 1h9c.827 0 1.5.673 1.5 1.5v6.402c-.808.347-1.707.62-1.885.62z"/>
                        <ellipse
                            fill={
                                selected === 'exhibition' &&
                                action === 'exhibition' &&
                                showMenu
                                  ? colors.color_blue
                                  : colors.color_sidebar_icon
                            }
                            cx="10.433" cy="6.926" rx="1.767" ry="1.77"/>
                        <ellipse
                            fill={
                                selected === 'exhibition' &&
                                action === 'exhibition' &&
                                showMenu
                                  ? colors.color_selected_icon
                                  : colors.color_sidebar_icon
                            }
                            cx="5.777" cy="6.926" rx="1.767" ry="1.77"/>
                    </svg>
                    {selected === 'exhibition' && (
                        <p className="sidebar-icon-text">{t('sidebar.sponsors')}</p>
                    )}
                </div>
            </Tooltip>}

            {/* HOTLINE */}
            {hotline_enabled &&
                <Tooltip title={t('sidebar.hotline')}>
                    <div
                        className={`sidebar-icon ${selected === 'hotline' && action === 'hotline' && showMenu && 'border-side-bar'}`}
                        onClick={() => {
                          handleChange(`/events/${eventId}/${slug}/hotline/`)
                          dispatch(addMenu('hotline'))
                          setSelected('inbox')
                        }}
                    >
                        <Hotline color={selected === 'hotline' && action === 'hotline' && showMenu
                          ? colors.color_selected_icon
                          : colors.color_sidebar_icon}/>
                        {selected === 'hotline' &&
                            <p className="sidebar-icon-text">{t('sidebar.hotline')}</p>}
                    </div>
                </Tooltip>}

        </div>
  )
}

export default SideBar

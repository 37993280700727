import React from 'react'
import colors from '../../Colors.scss'

const Hotline = ({ color = colors.color_sidebar_icon, width = '30', height = '30' }) => {
  return (
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 141.73 141.73"
             width={width}
             height={height}
             fill={color}
        >
            <path
                d="M67.45,41.56L67.45,41.56l-4.53,0.01v11.34h4.53v74.83H78.8V41.56H67.45z M67.45,30.22H78.8V14.35H67.45V30.22z"/>
        </svg>
  )
}

export default Hotline

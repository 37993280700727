import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GroupChannelHandler } from '@sendbird/chat/groupChannel'
import { useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { toast, ToastContainer } from 'react-toastify'
import { useSelector } from 'react-redux'

// src ...
import { connectToSB, previousMessage } from '../../SendBird/action'
import './PrivatChat.scss'
import '../../../../assets/scss/Global.scss'
import Emoji from '../../../../components/Emoji/Emoji'
import { getReaction } from '../../action'
import { useGetAttendeeProfileEventQuery } from '../../../../redux/api/participtantApi/participantApi'
import { useGetAttendeeProfileQuery } from '../../../../redux/api/authApi/authApi'
import HeaderChat from './HeaderChat/HeaderChat'
import HeaderForMsg from './HeaderForMsg/HeaderForMsg'
import EmojiesContainer from './Emoji/EmojiesContainer'
import ShowEmoji from './Emoji/ShowEmoji'
import AddReaction from './Emoji/AddReaction'
import Buttons from './MsgContainer/Buttons/Buttons'
import Msg from './MsgContainer/Msg/Msg'
import FormChat from '../form/formChat'
import { useSendMessage } from '../../SendBird/submitHandlerInput'

const PrivateChat = () => {
  // hooks
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  // hooks
  const {
    setUpdateMsg, updateMsg, msgToSend, setMsgToSend,
    refreshMessage, showEmoji, setRefreshMessage, setShowEmoji,
    setMsgToCheck, msgId, setMsgId, channel, setChannel,
    setIsSend, file, setFile, setScrollBottom, scrollBottom, setLoading, loading
  } = useSendMessage()

  // url
  const href = document.location.href.split('/')
  const userId = href[href.length - 1]

  // redux
  const eventId = useSelector(state => state.globalValueReducer.eventId)
  const slug = useSelector(state => state.globalValueReducer.slug)
  // const userDetail = useSelector(state => state.authReducer.user)

  // state
  const [sendBird, setSendBird] = useState()
  const [messagesFromDb, setMessageFromDb] = useState([])
  const [user, setUser] = useState()
  const [showMsgDetail, setShowMsgDetail] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [msgEmotion, setMsgEmotion] = useState()
  const [scrollBottomOneTime, setScrollBottomOneTime] = useState('')
  const [limit] = useState(50)
  const [focusInput, setFocusInput] = useState(false)

  // Emoji
  const [emojiReaction, setEmojiReaction] = useState([])
  const [chosenEmoji, setChosenEmoji] = useState(null)
  const [showEmojiEmotion, setShowEmojiEmotion] = useState(false)

  // api
  const { data: user2 } = useGetAttendeeProfileEventQuery({ eventId, profileId: userId })
  // redux
  const mainUserId = useSelector(state => state.authReducer.userId)

  // api
  const { data: userDetail } = useGetAttendeeProfileQuery(mainUserId)
  useEffect(() => {
    if (chosenEmoji) {
      setMsgToSend(msgToSend + chosenEmoji.emoji)
    }
  }, [chosenEmoji])

  useEffect(() => {
    if (userId && !user && userDetail?.sendbird_access_token) {
      connectToSB().then(async value => {
        setSendBird(value)
      })
    }
  }, [userDetail?.sendbird_access_token, sendBird])
  const connectUser = async () => {
    const connected = await sendBird?.connect(userDetail?.sendbird_user_id, userDetail?.sendbird_access_token)
    setUser(connected)
    return connected
  }
  useEffect(() => {
    if (!user && userDetail && sendBird) {
      connectUser().then(value => setUser(value)).catch(() => {
        connectUser()
      })
    }
  }, [userDetail, sendBird])
  const GroupChannelCreateParams = async () => {
    try {
      if (user2?.sendbird_user_id !== user?.userId) {
        const params = { // OpenChannelCreateParams can be imported from @sendbird/chat/openChannel.
          name: `${user?.userId}_chat_${user2?.sendbird_user_id}`,
          invitedUserIds: [user?.userId, user2?.sendbird_user_id],
          isDistinct: true
        }
        const channel = await sendBird?.groupChannel.createChannel(params)
        setChannel(channel)
        return channel
      } else {
        navigate(`/events/${eventId}/${slug}/inbox/`)
      }
    } catch (err) {
      // console.log(' Handle error', err)
      // Handle error.
    }
  }

  const channelHandler = new GroupChannelHandler({
    onMessageUpdated: (channel, message) => {
      setRefreshMessage(message)
    },

    onMessageReceived: (channel, message) => {
      setRefreshMessage(message)
    },
    onMessageDeleted: (channel, message) => {
      setRefreshMessage(message)
      toast(t('pages,inboxpage.chats.message-deleted'), {
        position: 'top-right',
        toastId: 'toastPrivat'
      })
    },

    onReactionUpdated: (channel, reactionEvent) => {
      (async () => {
        const params = {
          messageId: reactionEvent.messageId,
          channelType: 'group',
          channelUrl: channel.url
        }
        const msg = await sendBird?.message.getMessage(params)
        msg.applyReactionEvent(reactionEvent)
        setRefreshMessage(reactionEvent)
      })()
    }
  }
  )

  useEffect(() => {
    if (channel) {
      (async () => {
        await channel?.markAsRead()
      })()
      if (msgId) {
        // getReactionMsg('group_channels',channel?.url,msgId).then(value => console.log(value))
      }
      getReaction().then(value => setEmojiReaction(value.emoji_categories[0].emojis))
    }
  }, [channel, refreshMessage, msgId])

  useEffect(() => {
    if (user && user2 && sendBird) {
      GroupChannelCreateParams().then((channel) => {
        setIsLoading(true)

        previousMessage(channel, limit).then(value => {
          setMessageFromDb(value)

          if (!scrollBottomOneTime) {
            setScrollBottomOneTime('bottom')
          }
        }).finally(() => {
          setIsLoading(false)
        })
      })
      sendBird?.groupChannel.addGroupChannelHandler(`${user?.userId} + ${user2?.sendbird_user_id} `, channelHandler)
      setRefreshMessage(!refreshMessage)
    }
  }, [sendBird, user, user2])

  const SubmitHandler = async (e) => {
    e.preventDefault()
    setIsSend(true)
    setFocusInput(false)
    e.target.file.value = ''
  }
  useEffect(() => {
    if (user) {
      previousMessage(channel, limit).then(value => {
        if (!scrollBottomOneTime) {
          setScrollBottomOneTime('bottom')
        }
        setScrollBottom(true)
        setLoading(true)
        setMessageFromDb(value)
      })
    }
  }, [refreshMessage])

  // scroll to last message
  function useChatScroll (dev, dev2, dev3, dev4, loading) {
    const ref = useRef()
    useEffect(() => {
      if (dev3 === 'bottom' && !!dev4) {
        ref.current.scrollTop = ref.current.scrollHeight
        setScrollBottomOneTime('close')
      }

      if (dev && loading) {
        ref.current.scrollTop = ref.current.scrollHeight
        setScrollBottom(false)
        setLoading(false)
      }
    }, [dev, dev2, dev3, dev4, loading])
    return ref
  }

  const ref = useChatScroll(scrollBottom, refreshMessage, scrollBottomOneTime, messagesFromDb, loading)

  const showReactionEmoji = () => {
    setShowEmojiEmotion(!showEmojiEmotion)
  }

  return (
        <>
            <ToastContainer/>
            <HeaderChat user2={user2} sendBird={sendBird}/>
            {isLoading && <CircularProgress className="circularProgress"/>}
            <div className="chat-main-private"
                 ref={ref}
                 id="chat-main-scroll"
            >
                {messagesFromDb && messagesFromDb.map((msg) => {
                  return (
                        <div
                            key={msg?.messageId} className="msg-div" style={{
                              alignItems: msg?.sender.userId === user?.userId ? 'flex-end' : 'flex-start'
                            }}>
                            <HeaderForMsg msg={msg} msgId={msgId} user2={user2}
                                          user={user} setMsgId={setMsgId}
                                          showMsgDetail={showMsgDetail}
                                          setShowMsgDetail={setShowMsgDetail}/>

                            <div
                                style={{ padding: '14px 16px 26px' }}
                                className={msg?.sender.userId === user?.userId ? 'my-msg msg' : 'not-my-msg msg'}
                            >

                                <Msg msg={msg} user={user}/>

                                {showMsgDetail && msg?.messageId === msgId && <div
                                    style={{ borderRadius: msg?.sender.userId === user?.userId ? ' 20px 0 20px 20px' : '0 20px 20px 20px' }}
                                    className="show-msg-detail">
                                    <Buttons msg={msg} user={user} editAction={() => {
                                      setMsgToSend(msg.message)
                                      setMsgToCheck(msg.message)
                                      setShowMsgDetail(!showMsgDetail)
                                      setUpdateMsg(true)
                                      setFocusInput(true)
                                    }} deleteAction={{ channel, msg }}
                                    />
                                </div>}

                                {msg?.reactions[0]
                                  ? <ShowEmoji msg={msg} user={user} emojiReaction={emojiReaction}/>
                                  : msg?.sender.userId !== user?.userId
                                    ? <AddReaction msg={msg}
                                                   showReactionEmoji={showReactionEmoji}
                                                   setMsgId={setMsgId}
                                                   setMsgEmotion={setMsgEmotion}
                                        />
                                    : ''}

                            </div>

                            <EmojiesContainer emojiReaction={emojiReaction} showReactionEmoji={showReactionEmoji}
                                              msg={msg} msgId={msgId} user={user} channel={channel}
                                              showEmojiEmotion={showEmojiEmotion}
                                              msgEmotion={msgEmotion}
                            />
                        </div>
                  )
                })}
            </div>
            {showEmoji && <Emoji setChosenEmoji={setChosenEmoji} updateMsg={updateMsg}/>}
            <FormChat
                file={file}
                updateMsg={updateMsg}
                msgToSend={msgToSend}
                setFile={setFile}
                showEmoji={showEmoji}
                setShowEmoji={setShowEmoji}
                setMsgToSend={setMsgToSend}
                SubmitHandler={SubmitHandler}
                setUpdateMsg={setUpdateMsg}
                focusInput={focusInput}
            />
        </>
  )
}

export default PrivateChat

import { OpenChannelHandler } from '@sendbird/chat/openChannel'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

export const useOpenChannelHandler = ({ setRefreshMessage, refreshMessage }) => {
  const { t, i18n } = useTranslation()
  const channelHandler = new OpenChannelHandler({
    onMessageUpdated: (channel, message) => {
      setRefreshMessage(message)
    },
    onMessageReceived: (channel, message) => {
      setRefreshMessage(message)
    },
    onMessageDeleted: (channel, message) => {
      setRefreshMessage(message)
      toast(t('pages.inboxpage.chats.message-deleted'), {
        position: 'top-right',
        toastId: 'successId'
      })
    }
  })
  return { channelHandler }
}

export default useOpenChannelHandler

import i18n from './i18n'

export const getMonth = (date) => {
  const day = new Date(date).getMonth()
  switch (day) {
    case 0:
      return i18n.t('parser.jan')
    case 1:
      return i18n.t('parser.feb')
    case 2:
      return i18n.t('parser.mar')
    case 3:
      return i18n.t('parser.apr')
    case 4:
      return i18n.t('parser.may')
    case 5:
      return i18n.t('parser.june')
    case 6:
      return i18n.t('parser.july')
    case 7:
      return i18n.t('parser.aug')
    case 8:
      return i18n.t('parser.sep')
    case 9:
      return i18n.t('parser.oct')
    case 10:
      return i18n.t('parser.nov')
    case 11:
      return i18n.t('parser.dec')
    default:
      break
  }
}

export const To12Hours = (time) => {
  const d = time.split(':')[0]
  const mins = time.split(':')[1]
  const extra = d % 12
  const hour = extra === 0 ? 12 : extra
  const am = d >= 12 ? 'PM' : 'AM'
  const final = hour + ':' + mins + ' ' + am
  return final
}

export const getWeekLong = (day) => {
  let a
  switch (day) {
    case 'Mon':
      return 'Monday'
    case 'Tue':
      return 'Tuesday'
    case 'Wed':
      return 'Wednesday'
    case 'Thu':
      return 'Thursday'
    case 'Fri':
      return 'Friday'
    case 'Sat':
      return 'Saturday'
    case 'Sun':
      return 'Sunday'
    default:
      return day // Return the original day if not recognized
  }
}

import React from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

// src
import EventTitle from '../EventTitle/EventTitle'
import IsOnlineAgenda from '../../../SVG/global/isOnlineAgenda'

const TitleEvent = () => {
  // hooks
  const { pathname } = useLocation()
  const { t, i18n } = useTranslation()

  // env
  const speakerView = process.env.REACT_APP_SPEAKER_VIEW

  // redux
  const upcomingEvent = useSelector(state => state.agendaReducer.upcomingEvent)
  const liveEvent = useSelector(state => state.agendaReducer.liveEvent)
  const eventFromVideo = useSelector(state => state.globalValueReducer.eventFromVideo)
  const show_live_now = useSelector(state => state.globalValueReducer.show_live_now)
  let event = liveEvent?.start ? liveEvent : upcomingEvent
  if (pathname.includes('video')) {
    event = eventFromVideo
  }

  return (
        <>
            {
                event?.start
                  ? <>
                        {(liveEvent.start || pathname.includes('video')) && show_live_now ? <IsOnlineAgenda width='100'/>
                          : <p>{t('layout.coming-up-next')} {event?.start}</p>}
                        <EventTitle speakers={event?.speakers?.length === 1 ? event?.speakers[0] : false}
                                    speakerView={speakerView}/>
                        {' '} <p>{event?.title}</p>
                    </>
                  : ''
            }
        </>
  )
}

export default TitleEvent

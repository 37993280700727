import React from 'react'
import colors from '../../Colors.scss'

const QuestionsIcon = ({ height = '30', width = '30', color = 'white', opacity = '1' }) => {
  return (
      <svg
            width={width}
            height={height}
            viewBox="0 0 141.73 141.73"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M76.69,111.86H65.35 v15.87h11.34V111.86z M105.99,49.48c0-16.95-12.06-31.14-28.06-34.42c-2.29-0.47-4.66-0.71-7.07-0.71 c-19.38,0.01-35.14,15.76-35.14,35.13h11.34c0-13.12,10.68-23.78,23.81-23.78c4.91,0,9.49,1.5,13.29,4.06 c6.32,4.28,10.49,11.53,10.49,19.73c0,13.11-10.66,23.78-23.78,23.78h-5.51v2.67v13.2v11.38h11.34v-16.5 C93.28,81.23,105.99,66.86,105.99,49.48L105.99,49.48z"
                fill={color} fillOpacity={opacity}
            />
        </svg>
  )
}

export default QuestionsIcon

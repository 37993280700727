import React from 'react'
import { useTranslation } from 'react-i18next'

import Global from '../../SVG/Presence/Global'
import OnSiteStatus from '../../SVG/Presence/OnSiteStatus'
import colors from '../../Colors.scss'

const Presence = ({ status }) => {
  const { t, i18n } = useTranslation()
  return (
        <div className="presence-main">
            <div className="presence-main__centred">{status !== 'IN_PERSON'
              ? <Global color={colors.color_in_person} width="14" height="14"/>
              : <OnSiteStatus width={12} height={12} color={colors.color_on_site}/>}</div>
            <div><p>{status !== 'IN_PERSON' ? t('presence.remote') : t('presence.onsite')}</p></div>
        </div>
  )
}

export default Presence

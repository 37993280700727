import React from 'react'

const ArrowPlayer = ({ height = '34', width = '56', color = 'white', colorArrow = 'white', opacity = '1' }) => {
  return (
  // <svg width={width} height={height} viewBox="0 0 56 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  //     <rect width="56" height="34" rx="6" fill={color} fillOpacity={opacity}/>
  //     <path d="M20 16.9999V13.4399C20 9.01989 23.13 7.2099 26.96 9.4199L30.05 11.1999L33.14 12.9799C36.97 15.1899 36.97 18.8099 33.14 21.0199L30.05 22.7999L26.96 24.5799C23.13 26.7899 20 24.9799 20 20.5599V16.9999Z" stroke={colorArrow} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  // </svg>

    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 0 141.73 141.73" width={width} height={height}>
    <polygon fill={color} fillOpacity={opacity} points="32.39,6.66 24.8,17.07 95.05,68.27 37.67,110.09 37.67,50.23 24.8,50.23 24.8,135.42 116.92,68.27 "/>
    </svg>
  )
}

export default ArrowPlayer

import React, { useState } from 'react'
import { Button, FormControl, InputAdornment, TextField } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import './ChangePassword.scss'
import { GradiantBtn } from '../../assets/muiStyles/Button-style'
import { useChangePasswordCallMutation } from '../../redux/api/authApi/authApi'

const ChangePassword = () => {
  const { t, i18n } = useTranslation()

  // State management
  const [password1, setPassword1] = useState('')
  const [password1Eye, setPassword1Eye] = useState(false)
  const [password2, setPassword2] = useState('')
  const [password2Eye, setPassword2Eye] = useState(false)
  const [oldPassword, setOldPassword] = useState('')
  const [oldPasswordEye, setOldPasswordEye] = useState(false)
  const [screen, setScreen] = useState('changePass')
  const [loader, setLoader] = useState(false)
  const [error, setError] = useState(false)

  // API call
  const [changePasswordCall] = useChangePasswordCallMutation()

  const ChangePasswordAPI = async () => {
    changePasswordCall({ password1, oldPassword })
      .unwrap()
      .then(() => {
        setScreen('changePass2')
      })
  }

  const handleSubmitChange = (e) => {
    e.preventDefault()
    setLoader(true)

    if (password1.length === 0 || password2.length === 0 || oldPassword.length === 0) {
      toast.error(t('changepassword.toast-error-required'), { position: 'top-center' })
    } else if (password1 !== password2) {
      setError(true)
      toast.error(t('changepassword.toast-error-passwords-didnt-match'), { position: 'top-center' })
    } else if (password1.length < 6 || password2.length < 6) {
      setError(true)
      toast.error(t('changepassword.toast-error-characters-at-least-6'), { position: 'top-center' })
    } else {
      setError(false)
      ChangePasswordAPI()
    }
    setLoader(false)
  }

  return (
        <>
            <div className="title-change-password">
                <h2>
                    {t('changepassword.title')}
                    <span>{t('changepassword.protect-your-account')}</span>
                </h2>
            </div>
            {screen === 'changePass' ? (
                <form onSubmit={handleSubmitChange} noValidate className="form-change-password">
                    <div className="change-form-wrap">
                        <FormControl style={{ width: window.screen.width <= 1024 ? '100%' : '32%' }}>
                            <TextField
                                error={error}
                                onFocus={() => setError(false)}
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                                placeholder={t('changepassword.placeholder-enter-password')}
                                type={!oldPasswordEye ? 'password' : 'text'}
                                variant="outlined"
                                required
                                InputProps={{
                                  endAdornment: (
                                        <InputAdornment position="end">
                                            {oldPasswordEye
                                              ? <VisibilityIcon onClick={() => setOldPasswordEye(!oldPasswordEye)}/>
                                              : <VisibilityOffIcon onClick={() => setOldPasswordEye(!oldPasswordEye)}/>}
                                        </InputAdornment>
                                  )
                                }}
                            />
                        </FormControl>
                        <FormControl style={{ width: window.screen.width <= 1024 ? '100%' : '32%' }}>
                            <TextField
                                error={error}
                                onFocus={() => setError(false)}
                                value={password1}
                                onChange={(e) => setPassword1(e.target.value)}
                                placeholder={t('changepassword.placeholder-enter-new-password')}
                                type={!password1Eye ? 'password' : 'text'}
                                variant="outlined"
                                required
                                InputProps={{
                                  endAdornment: (
                                        <InputAdornment position="end">
                                            {password1Eye
                                              ? <VisibilityIcon onClick={() => setPassword1Eye(!password1Eye)}/>
                                              : <VisibilityOffIcon onClick={() => setPassword1Eye(!password1Eye)}/>}
                                        </InputAdornment>
                                  )
                                }}
                            />
                        </FormControl>
                        <FormControl style={{ width: window.screen.width <= 1024 ? '100%' : '32%' }}>
                            <TextField
                                error={error}
                                onFocus={() => setError(false)}
                                value={password2}
                                onChange={(e) => setPassword2(e.target.value)}
                                placeholder={t('changepassword.placeholder-confirm-password')}
                                type={!password2Eye ? 'password' : 'text'}
                                variant="outlined"
                                required
                                InputProps={{
                                  endAdornment: (
                                        <InputAdornment position="end">
                                            {password2Eye
                                              ? <VisibilityIcon onClick={() => setPassword2Eye(!password2Eye)}/>
                                              : <VisibilityOffIcon onClick={() => setPassword2Eye(!password2Eye)}/>}
                                        </InputAdornment>
                                  )
                                }}
                            />
                        </FormControl>
                    </div>
                    <br/>
                    <div className="change-password-btn-container">
                        <GradiantBtn
                            sx={{ justifySelf: 'flex-end', width: window.screen.width <= 1024 ? '100%' : '35%' }}
                            type="submit"
                            loading={loader}
                        >
                            {loader ? t('changepassword.saving') : t('changepassword.button-text')}
                        </GradiantBtn>
                    </div>
                </form>
            ) : (
                <div className="forgot-pass-form-success change-success">
                    {/* Success Message and Button to reset form or navigate */}
                    <Button
                        style={{ marginTop: '36px', padding: '15px' }}
                        type="submit"
                        variant="contained"
                        onClick={() => {
                          setOldPassword('')
                          setPassword1('')
                          setPassword2('')
                          setScreen('changePass')
                        }}
                    >
                        {t('changepassword.ok')}
                    </Button>
                </div>
            )}
        </>
  )
}

export default ChangePassword

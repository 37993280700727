import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Intl
import { AsqBtn } from '../../../assets/muiStyles/Button-style'

// Redux
import { useGlobalValue } from '../../../hooks/redux/reduxHooks'

const AsqBtnComponent = ({ id, referrer, question_type = 'question_all' }) => {
  const navigate = useNavigate()
  const { slug, eventId } = useGlobalValue()
  const { t, i18n } = useTranslation()

  return (
        <AsqBtn
            onClick={() =>
              navigate(`/events/${eventId}/${slug}/questions/`, {
                state: { id, referrer }
              })
            }
        >
            <p className="mT2px">
                {(() => {
                  switch (question_type) {
                    case 'question_all':
                      return t('buttons.asqbtn.ask-question')
                    case 'question_poster':
                      return t('buttons.asqbtn.ask-question')
                    case 'question_speaker':
                      return t('buttons.asqbtn.ask-speaker')
                    default:
                      return t('buttons.asqbtn.ask-speaker')
                  }
                })()}
            </p>
        </AsqBtn>
  )
}

export default AsqBtnComponent

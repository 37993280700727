import React from 'react'
import colors from '../../Colors.scss'

const PollIcon = ({ height = '30', width = '30', color = 'white', opacity = '1' }) => {
  return (
      <svg
            width={width}
            height={height}
            viewBox="0 0 141.73 141.73"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M65.43,78.92h10.85v37.47H65.43V78.92z M54.09,127.73h33.53V67.58H54.09V127.73z M25.51,54.53h10.84v61.86 H25.51V54.53z M14.16,127.73h33.53V43.18H14.16V127.73z M105.37,25.69h10.84v90.69h-10.84V25.69z M94.03,127.73h33.53V14.35H94.03 V127.73z"
                fill={color} fillOpacity={opacity}
            />
        </svg>
  )
}

export default PollIcon

import React from 'react'
import colors from '../../Colors.scss'

const DownloadsIcon = ({ height = '30', width = '30', color = 'white', opacity = '1' }) => {
  return (
      <svg
          width={width}
          height={height}
          viewBox="0 0 150.000000 150.000000"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              // fillRule="evenodd"
              // clipRule="evenodd"
              d="M688 976 l-3 -374 -175 169 -175 168 -40 -40 -40 -39 248 -248 247 -247 247 247 248 248 -43 42 -42 43 -175 -175 -175 -175 0 378 0 377 -60 0 -60 0 -2 -374z"
              fill={color} fillOpacity={opacity}
          />
          <path
              // fillRule="evenodd"
              // clipRule="evenodd"
             d="M150 295 l0 -145 600 0 600 0 0 145 0 145 -60 0 -60 0 0 -85 0 -85 -480 0 -480 0 0 85 0 85 -60 0 -60 0 0 -145z"
              fill={color} fillOpacity={opacity}
          />
      </svg>
  )
}

export default DownloadsIcon

import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined'
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { InputBase } from '@mui/material'
import SendOutlinedIcon from '@mui/icons-material/Send'
import colors from '../../../../../Colors.scss'

const Input = ({ setShowEmoji, showEmoji, file, setFile, setMsgToSend, msgToSend, focusInput }) => {
  const { t, i18n } = useTranslation()
  return (
        <Box className="send-msg">
            {/* {chosenEmoji ? ( */}
            {/*    <span>You chose: {chosenEmoji.emoji}</span> */}
            {/* ) : ( */}
            {/*    <span>No emoji Chosen</span> */}
            {/* )} */}
            <div className="send-msg-input-flex-center" style={{ width: '100%' }}>
                <EmojiEmotionsOutlinedIcon
                    onClick={() => {
                      setShowEmoji(!showEmoji)
                    }}
                    sx={{ color: colors.primary, mr: 1, my: 0.5, cursor: 'pointer' }}
                />
                <input type="file"
                       name="file"
                       id="fileMsg" style={{ display: 'none' }}
                       hidden
                       onChange={(e) => {
                         setFile(e.target.files[0])
                       }}/>
                <AttachFileOutlinedIcon
                    className="file-chose-chat-icon"
                    onClick={() => {
                      document.getElementById('fileMsg').click()
                    }}
                    sx={{ color: colors.primary, mr: 1, my: 0.5 }}
                />
                {!!file && (
                    <>
                        <p>{file.name}</p>
                        <p className="delete-file-chat-icon"
                           onClick={() => {
                             const fileInput = document.getElementById('fileMsg')
                             fileInput.value = null
                             setFile('')
                           }}><RemoveCircleIcon/></p>
                    </>)}
                <InputBase style={{ width: '100%' }} name="message" value={msgToSend}
                           placeholder={t('pages.inboxpage.chats.type-message')}
                           id="input-with-sx"
                           hidden
                    // multiline
                           rows={3}
                           type="input"
                           variant="standard"
                           onChange={(e) => {
                             setMsgToSend(e.target.value)
                           }}
                           inputRef={input => focusInput && input?.focus()}
                />
            </div>
            <div className="send-msg-input-flex-center">
                <button className="btn-reset-style">
                    <SendOutlinedIcon sx={{ color: colors.primary_two }}/>
                </button>
            </div>
        </Box>
  )
}

export default React.memo(Input)

import React, { useLayoutEffect, useRef } from 'react'
import AttachEmailIcon from '@mui/icons-material/AttachEmail'
import './Msg.scss'

const Msg = ({ msg, user }) => {
  const message = useRef(null)
  function extractURLs (text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g
    return text?.replace(urlRegex, function (url) {
      return '<a href="' + url + '" class="highlighted-url">' + url + '</a>'
    })
  }
  useLayoutEffect(() => {
    const text = extractURLs(msg?.message)
    if (message.current) {
      message.current.innerHTML = text
    }
  }, [message, user, msg])
  return (
        <>
            {msg?.type === 'application/pdf' || msg?.type === 'text/plain'
              ? <a style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 10
              }}
                     className={msg?.sender.userId === user?.userId
                       ? 'chat-file-text-my-msg'
                       : 'chat-file-text-not-my-msg'}
                     href={msg?.url} target="_blank" rel="noreferrer"> {msg?.sender.userId === user?.userId
                       ? <>{msg?.name} <AttachEmailIcon/></>
                       : <> <AttachEmailIcon/> {msg?.name} </>}
                </a>
              : msg?.type === 'image/jpeg' || msg?.type === 'image/png'
                ? <div className="chat-msg-img"><img src={msg?.url}
                                                         alt="Image"/>
                    </div>
                // : <div className="message-desc" style={{ color: msg?.sender?.userId === user?.userId ? 'white' : 'black' }} ref={message}/>
                : <div className="message-desc" ref={message}/>
            }
        </>
  )
}

export default React.memo(Msg)

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Paper from '@mui/material/Paper'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { CircularProgress, ThemeProvider } from '@mui/material'
import { toast, ToastContainer } from 'react-toastify'
import parse from 'html-react-parser'
import { useSelector } from 'react-redux'

// src
import './LivePollPage.scss'
import { appTheme } from '../../theme'
// import colors from '../../Colors.scss'
// import ParticipantsSvg from '../../SVG/ParcipantsSvg'
// import PollSVG from '../../SVG/pages/Poll/PollSvg'
// import Questions from '../../SVG/pages/question/Questions'
// custom SFS
import PollIcon from '../../SVG/custom/PollIcon'
import QuestionsIcon from '../../SVG/custom/QuestionsIcon'
import ContextHelp from '../../components/ContextHelp/ContextHelp'
import colors from '../../Colors.scss'
import {
  useAddPollAnswerMutation,
  useGetPollsListAnswersQuery,
  useGetPolsListQuery,
  useUpdatePollAnswerMutation
} from '../../redux/api/getLivePollApi/getLivePollApi'
import { useGetUserAccessQuery } from '../../redux/api/userApi/userApi'

const LivePollPage = () => {
  // redux
  const eventId = useSelector(state => state.globalValueReducer.eventId)
  const { t, i18n } = useTranslation()

  // state
  const [pollId, setPollId] = useState(0)
  // const [setLoad] = useState(false)
  const [showModalHelper, setShowModalHelper] = useState({
    open: false,
    event: {}
  })

  // api
  const [updatePollAnswer] = useUpdatePollAnswerMutation()
  const [addPollAnswer] = useAddPollAnswerMutation()
  const { data: pollsList, isLoading, isFetching } = useGetPolsListQuery(eventId)
  const { data: pollsAnswers } = useGetPollsListAnswersQuery({ eventId, pollId }, {
    skip: pollId === 0
  })

  const { data: userAccess } = useGetUserAccessQuery(eventId, { skip: !eventId })

  const pollsPercentage = (vote_count, total) => {
    if (total !== 0) return Math.floor((vote_count / total) * 100)
    else return 0
  }

  const handleRadio = async (pollId, voteId, item_id, user_voted, mutable) => {
    if (user_voted) {
      if (mutable) {
        updatePollAnswer({ arg: { eventId, pollId, voteId }, item_id })
          .unwrap()
          .then(value => {
            if (value) {
              setPollId(pollId)
            }
          })
        //
        // const response = await GetPollsListAnswers(eventId, pollId)
        // const index = pollsList.findIndex((val) => val.id === pollId)
        // pollsList[index] = response.data
        // toast(
        //                 <div style={{ display: 'flex', gap: '10px' }}>
        //                     <ThemeProvider theme={appTheme}>
        //                         <CheckCircleIcon color="primary" />
        //                     </ThemeProvider>
        //                     <p><FormattedMessage id="your-vote-has-been-changes" defaultMessage="Your vote has been changed."/></p>
        //                 </div>,
        //                 {
        //                   position: 'top-right',
        //                   autoClose: 2000,
        //                   hideProgressBar: false,
        //                   closeOnClick: true,
        //                   pauseOnHover: true,
        //                   draggable: true,
        //                   progress: undefined
        //                 }
        // )
      }
    } else {
      addPollAnswer({ arg: { eventId, pollId }, item_id })
        .unwrap()
        .then(value => {
          if (value) {
            setPollId(pollId)
          }
        })
      // const index = pollsList.findIndex((val) => val.id === pollId)
      // pollsList[index] = response.data
      // toast(
      //               <div style={{ display: 'flex', gap: '10px' }}>
      //                   <ThemeProvider theme={appTheme}>
      //                    <CheckCircleIcon color="primary" />
      //                   </ThemeProvider>
      //                   <p><FormattedMessage id="thank-you-for-your-vote" defaultMessage="Thank you for your vote!"/></p>
      //               </div>,
      //               {
      //                 position: 'top-right',
      //                 autoClose: 2000,
      //                 hideProgressBar: false,
      //                 closeOnClick: true,
      //                 pauseOnHover: true,
      //                 draggable: true,
      //                 progress: undefined,
      //                 toastId: 'polls'
      //               }
      // )
      // response !== undefined &&
      // setPollsList(
      //   uniqueArray([response.data,...pollsList],'id')
      // )
      // console.log(pollsList)
    }
    // const response = await
  }

  useEffect(() => {
    if (pollsAnswers) {
      toast(
                <div style={{ display: 'flex', gap: '10px' }}>
                    <ThemeProvider theme={appTheme}>
                        <CheckCircleIcon color="primary"/>
                    </ThemeProvider>
                    <p>{t('pages.livepollpage.thank-you-for-your-vote')}</p>
                </div>,
                {
                  position: 'top-right',
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  toastId: 'polls'
                }
      )
    }
  }, [pollsAnswers])
  // Dont use
  // const GetFilterPollsListAPI = async (filterBy) => {
  //   setLoader(true)
  //   try {
  //     const values = await GetFilterPollsList(eventId, filterBy)
  //     if (values !== undefined) {
  //       const newArr = []
  //       const length = values.data?.items?.length
  //       for (let i = 0; i < length; i++) {
  //         const pollId = values.data?.items[i]?.id
  //         const ans = await GetPollsListAnswers(eventId, pollId)
  //         newArr.push(ans.data)
  //       }
  //       setPollsList(newArr)
  //       setLoader(false)
  //     } else {
  //       // RefreshToken();
  //       GetFilterPollsList(filterBy)
  //     }
  //   } catch (e) {
  //     console.log('error', e)
  //   }
  //   setLoader(false)
  // }

  // const handleFilterMenu = (e) => {
  //   const filterBy = e.id === 1 ? 'open' : 'closed'
  //   GetFilterPollsListAPI(filterBy)
  // }
  // const handleSearch = async (e) => {
  //   const searchChar = e.target.value
  //   const response = await GetPollsBySearch(eventId, searchChar)
  //   if (response !== undefined) {
  //     const newArr = []
  //     const length = response.data?.items?.length
  //     for (let i = 0; i < length; i++) {
  //       const pollId = response.data?.items[i]?.id
  //       const ans = await GetPollsListAnswers(eventId, pollId)
  //       newArr.push(ans.data)
  //     }
  //     setPollsList(newArr)
  //     setLoader(false)
  //   }
  // }

  return (
        <>
            <div className="livepoll-main">
                <div className="livepoll-inside">
                    <div className='livepoll-helper-mobile'
                         onClick={(e) => {
                           setShowModalHelper({
                             open: !showModalHelper.open,
                             event: e.currentTarget
                           })
                         }}>
                        <QuestionsIcon width='20' height='20' color={colors.color_sidebar_icon}/>
                        {showModalHelper && <ContextHelp helpText={{
                          id: 3,
                          what: t('pages.livepollpage.context-help-poll')
                        }} open={showModalHelper.open}
                            anchorEl={showModalHelper.event}
                            setShowModalHelper={setShowModalHelper}/>}
                    </div>
                    <div className="title-main">
                        <div className="title">
                            <PollIcon width='30' height='30' color={colors.color_sidebar_icon}/>
                            <p>
                                <span className="help_span"
                                      onClick={(e) => {
                                        setShowModalHelper({
                                          open: !showModalHelper.open,
                                          event: e.currentTarget
                                        })
                                      }}>
                                    {t('sidebar.polls')}
                                    {showModalHelper && <ContextHelp helpText={{
                                      id: 3,
                                      what: t('pages.livepollpage.context-help-poll')
                                    }} open={showModalHelper.open}
                                         anchorEl={showModalHelper.event}
                                         setShowModalHelper={setShowModalHelper}/>}
                                </span>
                            </p>

                            {/*          <p><FormattedMessage id="Polls" defaultMessage="Polls"/>&nbsp; */}
                            {/*          <span */}
                            {/*      onClick={(e) => { */}
                            {/*        setShowModalHelper({ */}
                            {/*          open: !showModalHelper.open, */}
                            {/*          event: e.currentTarget */}
                            {/*        }) */}
                            {/*      }}> */}
                            {/*  <QuestionsIcon color={colors.color_sidebar_icon} width='20' height='20'/> */}
                            {/*  {showModalHelper && <ContextHelp helpText={{ id: 3, what: <FormattedMessage id='live-poll-helper' defaultMessage='Speakers and Chairs may publish polls. Every congress participant can vote. ' /> }} open={showModalHelper.open} anchorEl={showModalHelper.event} setShowModalHelper={setShowModalHelper}/>} */}
                            {/* </span> */}
                            {/*      </p> */}
                        </div>
                    </div>
                    <div className="search">
                        {/* <FormattedMessage id="search-poll" defaultMessage="Search poll"> */}
                        {/*    { */}
                        {/*        placeholder => */}
                        {/*            <TextField */}
                        {/*                sx={{ */}
                        {/*                    '& label': {paddingLeft: (theme) => theme.spacing(1)}, */}
                        {/*                    '& input': {paddingLeft: (theme) => theme.spacing(1)}, */}
                        {/*                    '& fieldset': { */}
                        {/*                        paddingLeft: (theme) => theme.spacing(2), */}
                        {/*                        borderRadius: '30px', */}
                        {/*                    }, */}
                        {/*                }} */}
                        {/*                style={{width: "300px"}} */}
                        {/*                size="small" */}
                        {/*                placeholder={placeholder} */}
                        {/*                onChange={(e) => handleSearch(e)} */}

                        {/*    InputProps={{ */}
                        {/*        startAdornment: ( */}
                        {/*            <InputAdornment */}
                        {/*                position="center" */}

                        {/*            > */}
                        {/*                <SearchIcon  /> */}
                        {/*            </InputAdornment> */}
                        {/*        ), */}
                        {/*    }} */}
                        {/*    variant="outlined" */}
                        {/* /> */}
                        {/* <Popover */}
                        {/*    id={Boolean(anchorEl) ? "simple-popover" : undefined} */}
                        {/*    open={Boolean(anchorEl)} */}
                        {/*    anchorEl={anchorEl} */}
                        {/*    onClose={() => setAnchorEl(null)} */}
                        {/*    anchorOrigin={{ */}
                        {/*        vertical: "bottom", */}
                        {/*        horizontal: "left", */}
                        {/*    }} */}
                        {/* > */}
                        {/*    <p className="filter-menu-head">Sort By</p> */}
                        {/*    {filteMenu.map((menu,index) => ( */}
                        {/*        <p */}
                        {/*            key={index} */}
                        {/*            className="filter-menu" */}
                        {/*            onClick={() => { */}
                        {/*                handleFilterMenu(menu); */}
                        {/*                setAnchorEl(null); */}
                        {/*            }} */}
                        {/*        > */}
                        {/*            {menu.label} */}
                        {/*        </p> */}
                        {/*    ))} */}
                        {/* </Popover> */}
                        {/* <div className="live-poll-filter"> */}
                        {/*    <FilterListIcon */}
                        {/*        fontSize="medium" */}
                        {/*        onClick={(e) => setAnchorEl(e.currentTarget)} */}
                        {/*    /> */}
                        {/* </div> */}
                    </div>
                    <div className="livepoll-cards-main">
                        {isLoading && isFetching && <CircularProgress className='circularProgress'/>}
                        <div className="live-polls-container">
                            {!isLoading && !isFetching &&
                                (userAccess && userAccess?.event_features?.live_polls_enabled ? pollsList : pollsList.filter(el => el.poll_type !== 'LIVE_POLL'))?.map((polls, index) => (
                                    <Paper key={index} elevation={0}

                                           sx={{
                                             margin: '0 16px',
                                             borderRadius: '12px',
                                             boxShadow: 'none'
                                           }}>
                                        <Accordion defaultExpanded={!polls.poll_closed}
                                                   sx={{
                                                     boxShadow: 'none',
                                                     padding: '2px 5px '
                                                   }}
                                        >
                                            <ThemeProvider theme={appTheme}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon sx={{
                                                      background: colors.button_enabled_color,
                                                      color: colors.button_enabled_text_color,
                                                      borderRadius: '999px',
                                                      marginRight: '16px'

                                                    }}/>}
                                                    sx={{
                                                      width: '100%'
                                                    }}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography
                                                        sx={{
                                                          paddingLeft: '16px'
                                                        }}
                                                        className="polls-question-container-head">
                                                        {polls?.question}
                                                    </Typography>
                                                </AccordionSummary>
                                            </ThemeProvider>
                                            <AccordionDetails
                                                sx={{ padding: '0px 16px 16px' }}
                                            >

                                                <div className="polls-radio-group">
                                                    <RadioGroup value={polls.user_voted_item}>
                                                        {/* {load && <CircularProgress/>} */}
                                                        {polls.items.map((ans) => {
                                                          const pollsWithLink = parse(ans?.value)
                                                          return (
                                                                <div
                                                                    key={ans.id}
                                                                    className={`polls-answers-radio ${
                                                                        polls.user_voted_item === ans.id
                                                                            ? 'gradient'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    <FormControlLabel
                                                                        disabled={polls.poll_closed}
                                                                        value={ans.id}
                                                                        style={{
                                                                          color:
                                                                                polls.user_voted_item === ans.id
                                                                                  ? colors.color_black
                                                                                  : colors.color_form_control
                                                                        }}
                                                                        control={
                                                                            <Radio
                                                                                style={{ padding: '2px 5px' }}
                                                                                onChange={(e) => {
                                                                                  handleRadio(
                                                                                    polls.id,
                                                                                    polls.user_voted_id,
                                                                                    e.target.value,
                                                                                    polls.user_voted,
                                                                                    polls.mutable
                                                                                  )
                                                                                }
                                                                                }
                                                                                checkedIcon={
                                                                                    <ThemeProvider theme={appTheme}>
                                                                                        <CheckCircleIcon
                                                                                            color="primary"/>
                                                                                    </ThemeProvider>
                                                                                }
                                                                                // size="small"
                                                                            />

                                                                        }

                                                                        label={Array.isArray(pollsWithLink)
                                                                          ? <>
                                                                                <p>{pollsWithLink[0]}</p>
                                                                                <a style={{ color: 'rgb(107, 111, 122)' }}
                                                                                   target="_blank"
                                                                                   href={pollsWithLink[1]?.props?.href}
                                                                                   rel="noreferrer">
                                                                                    {pollsWithLink[1]?.props?.children} </a>
                                                                            </>

                                                                          : pollsWithLink?.type === 'a'
                                                                            ? <a style={{ color: 'rgb(107, 111, 122)' }}
                                                                                   target="_blank"
                                                                                   href={pollsWithLink.props.href}
                                                                                   rel="noreferrer">
                                                                                    {pollsWithLink?.props?.children} </a>
                                                                            : ans.value}

                                                                    />
                                                                    {polls.live_updates && (
                                                                        <p
                                                                            style={{
                                                                              color:
                                                                                    polls.user_voted_item === ans.id
                                                                                      ? colors.primary_dark
                                                                                      : colors.primary_dark
                                                                            }}
                                                                        >
                                                                            {pollsPercentage(
                                                                              ans.vote_count,
                                                                              polls.vote_count
                                                                            )}
                                                                            %
                                                                        </p>
                                                                    )}
                                                                </div>
                                                          )
                                                        })}

                                                    </RadioGroup>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Paper>
                                ))}
                        </div>
                    </div>
                </div>
                {/* <SideBar onChange={handleChange} selectedprop={"polls"} /> */}
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                theme="colored"
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
  )
}
export default LivePollPage

import React from 'react'
import colors from '../../Colors.scss'

const ParticipantsIcon = ({ height = '34', width = '56', color = 'white', opacity = '1' }) => {
  return (
      <svg
            width={width}
            height={height}
            viewBox="0 0 141.73 141.73"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
                <path fill={color} fillOpacity={opacity}
                      d="M87.88,42.69
                    c0,9.38-7.64,17.01-17.02,17.01c-9.38,0-17.02-7.62-17.02-17.01c0-9.38,7.63-17,17.02-17C80.24,25.69,87.88,33.32,87.88,42.69
                     M99.21,42.69c0-15.62-12.72-28.34-28.35-28.34c-15.64,0-28.35,12.72-28.35,28.34c0,15.63,12.71,28.34,28.35,28.34
                    C86.49,71.04,99.21,58.32,99.21,42.69 M127.56,127.73v-25.51L101.1,82.37H40.62l-26.45,19.85v25.51h11.34v-19.84l18.89-14.18h52.92
                    l18.9,14.18v19.84H127.56z"/>
        </svg>
  )
}

export default ParticipantsIcon

import React from 'react'
import { useTranslation } from 'react-i18next'
import UserPlaceholder from '../../../SVG/pages/eventAgenda/UserPlaceholder'

const PlaceholderTextViewAgenda = ({ people, title, count, activeAgenda }) => {
  const { t, i18n } = useTranslation()
  return (
        <>
            {
                title.toLowerCase() === 'speakers'
                  ? t('pages.eventagendapage.speakers')
                  : t('pages.eventagendapage.chairs')
            }

            <div className="chair-card-container">
                {people.slice(0, count).map(
                  (sp) => {
                    return <div className="chair-card cursor-pointer"
                                    key={sp.id}>
                            <div className='placeholder_text-event-agenda'>
                                {sp?.avatar?.download_url ? <img
                                    src={sp?.avatar?.download_url}
                                    alt={sp?.full_name}
                                    title={sp?.full_name}
                                    width="36px"
                                    height="36px"
                                /> : <UserPlaceholder width='28' height="28"/>}
                                <p>{sp?.full_name}</p>
                            </div>
                        </div>
                  }
                )}
                {people.length > count && <span
                    className={`${activeAgenda ? 'span-white' : 'span-black'}`}> + {people.length - count} {t('pages.eventagendapage.more')}</span>}
            </div>
        </>

  )
}

export default PlaceholderTextViewAgenda

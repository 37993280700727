import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  upcomingEvent: {},
  liveEvent: {},
  agendaEvents: [],
  agendaDays: [],
  todayDateId: false
}

const agendaEvents = createSlice({
  name: 'agendaEvents',
  initialState,
  reducers: {
    addUpcomingEvent: (state, action) => {
      state.upcomingEvent = action.payload
    },
    addLiveEvent: (state, action) => {
      state.liveEvent = action.payload
    },
    addAgendaEvents: (state, action) => {
      state.agendaEvents = action.payload
    },
    addAgendaDays: (state, action) => {
      state.agendaDays = action.payload
    },
    addTodayDateId: (state, action) => {
      state.todayDateId = action.payload
    }
  }
})

export const agendaReducer = agendaEvents.reducer
export const {
  addUpcomingEvent, addLiveEvent, addAgendaEvents, addAgendaDays,
  addTodayDateId
} = agendaEvents.actions

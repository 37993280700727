import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedDate: 0,
  eventId: '',
  IdEventDesc: '',
  slug: '',
  eventFromVideo: '',
  urlFromVideo: { videoUrlEmbed: '', videoUrlHls: '' },
  checkSelect: false,
  onceScrollAgenda: false,
  menu: '',
  agendaActive: {},
  navigateToAgenda: '',
  refresh_token_expiration: '',
  lastUrl: '',
  participantSorting: 'last_name',
  participantSortingTable: 'last_name',
  show_live_now: false
}

const globalValueSlice = createSlice({
  name: 'globalValueSlice',
  initialState,
  reducers: {
    addToken: (state, action) => {
      state.token = action.payload
    },
    deleteToken: (state) => {
      state.token = ''
    },
    addEventId: (state, action) => {
      state.eventId = action.payload.eventId
    },
    addSlug: (state, action) => {
      state.slug = action.payload
    },
    addEventFromVideo: (state, action) => {
      state.eventFromVideo = action.payload
    },
    removeEventFromVideo: (state) => {
      state.eventFromVideo = {}
    },
    addUrlFromVideo: (state, action) => {
      state.urlFromVideo = action.payload.urlFromVideo
    },
    isSelect: (state, action) => {
      state.checkSelect = action.payload
    },
    onceScrollAgendaCheck: (state, action) => {
      state.onceScrollAgenda = action.payload
    },
    removeUrlFromVideo: (state) => {
      state.urlFromVideo = { videoUrlEmbed: '', videoUrlHls: '' }
    },
    addMenu: (state, action) => {
      state.menu = action.payload
    },
    addAgendaActive: (state, action) => {
      state.agendaActive = action.payload
    },
    addNavigateToAgenda: (state, action) => {
      state.navigateToAgenda = action.payload
    },
    addSelectedDate: (state, action) => {
      state.selectedDate = action.payload
    },
    addRefreshTokenExpiration: (state, action) => {
      state.refresh_token_expiration = action.payload
    },
    addLastUrl: (state, action) => {
      state.lastUrl = action.payload
    },
    addSortParticipant: (state, action) => {
      state.participantSorting = action.payload
    },
    addSortParticipantTable: (state, action) => {
      state.participantSortingTable = action.payload
    },
    changeShowLiveNow: (state, action) => {
      state.show_live_now = action.payload
    }
  }
})

export const globalValueReducer = globalValueSlice.reducer
export const {
  addEventId, addSlug, addEventFromVideo, removeEventFromVideo,
  addUrlFromVideo, removeUrlFromVideo, isSelect, onceScrollAgendaCheck,
  addMenu, addAgendaActive, addNavigateToAgenda, addSelectedDate,
  addRefreshTokenExpiration, changeShowLiveNow, addLastUrl, addSortParticipantTable, addSortParticipant
} = globalValueSlice.actions

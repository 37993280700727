import React from 'react'
import { useTranslation } from 'react-i18next'
import UserPlaceholder from '../../../SVG/pages/eventAgenda/UserPlaceholder'

const EventTitle = ({ speakers = false, speakerView = '' }) => {
  const { t, i18n } = useTranslation()
  return (
        <>
            {!!speakers &&
                <>
                    {
                        speakerView === 'avatar' &&

                        <>
                            {speakers?.avatar?.download_url ? <img
                                    style={{ width: '45px', height: '45px' }}
                                    src={speakers?.avatar?.download_url}
                                    alt={t('layout.eventtitle.speaker')}
                                    title={speakers?.full_name}
                                    width="50px"
                                    height="50px"
                                />
                              : <UserPlaceholder width='51' height='51'/>}

                        </>

                    }
                    {
                        speakerView === 'avatar_text' &&
                        <>
                            {speakers?.avatar?.download_url ? <img
                                style={{ width: '45px', height: '45px' }}
                                src={speakers?.avatar?.download_url}
                                alt={t('layout.eventtitle.speaker')}
                                title={speakers?.full_name}
                                width="55px"
                                height="55px"/> : <UserPlaceholder width='55' height="55"/>}
                            <p>{speakers?.full_name}</p>
                        </>

                    }
                    {
                        speakerView === 'placeholder_text' &&
                        <>
                            {speakers?.avatar?.download_url ? <img
                                key={speakers.id}
                                src={speakers?.avatar?.download_url}
                                alt={t('layout.eventtitle.speaker')}
                                title={speakers?.full_name}
                                width="46px"
                                height="46px"
                            /> : <UserPlaceholder width={'40'} height={'40'}/>}
                            <p>{speakers?.full_name}</p>
                        </>

                    }
                    {
                        speakerView === 'text' &&
                        <span>{ speakers?.full_name}  </span>
                    }
                </>

            }
        </>
  )
}

export default EventTitle

import React from 'react'
import logo from '../../images/sfs/logo.png'
import { useNavigate } from 'react-router-dom'

export default function HeadLogo () {
  const navigate = useNavigate()
  return (
        <div className='logo-container'
             onClick={() => {
               localStorage.removeItem('evId')
               navigate('/')
             }}

        >
            <a target='_blank' href="https://sfs.com/" rel="noreferrer">
                <img src={logo} alt="logo" height={50}/></a>
            <h2
                onClick={() => {
                  localStorage.removeItem('evId')
                  navigate('/')
                }}
            >&nbsp;</h2>
        </div>
  )
}

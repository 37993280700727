import React from 'react'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import { useSelector } from 'react-redux'
import { useGetPosterDetailQuery } from '../../../redux/api/posterApi/posterApi'
import Attachments from '../../../components/Attachments/Attachments'
import AsqBtnComponent from '../../../components/Buttons/AsqBtn/AsqBtn'

const PosterDetailMobile = () => {
  // url
  const posterId = window.location.href.split('/').slice(-3)[0].toString()
  const { t, i18n } = useTranslation()

  // redux
  const eventId = useSelector(state => state.globalValueReducer.eventId)

  // api
  const { data: posterDetail } = useGetPosterDetailQuery({ eventId, posterId })

  return (
        <div className="mobile-desc">
            {posterDetail?.body && <div className="mobile-desc-p">
                {posterDetail?.body && posterDetail?.body.map((desc, index) => {
                  return (
                        <div key={index}>{parse(desc?.value)}</div>
                  )
                })}
            </div>}
            <div>
                <Attachments event={posterDetail}/>
                <div className="qsk-container-mobile">
                    <p className="qsk-container-mobile__desc">
                        <span className="span-poster-desc">
                            <strong>{t('pages.posters.post-question')}</strong>
                        </span>
                        <br/>
                        {t('pages.posters.do-you-want-to-ask-author')}
                    </p>
                    <AsqBtnComponent id={posterDetail?.id} referrer='poster' question_type='question_poster'/>
                </div>
            </div>

        </div>
  )
}
export default PosterDetailMobile

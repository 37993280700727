import React from 'react'
import { useTranslation } from 'react-i18next'
import TextViewAgenda from './TextViewAgenda'
import AvatarTextViewAgenda from './AvatarTextViewAgenda'
import AvatarViewAgenda from './AvatarViewAgenda'
import PlaceholderTextViewAgenda from './PlaceholderTextViewAgenda'

const SpeakersAndChairsAgenda = ({ event }) => {
  const { t, i18n } = useTranslation()
  // Env
  // const speakerView = process.env.REACT_APP_SPEAKER_VIEW
  // const chair = process.env.REACT_APP_CHAIRS_VIEW
  let peopleViewCount = process.env.REACT_APP_PEOPLE_COUNT_ON_AGENDA
  const speakerView = 'placeholder_text'
  const chair = 'placeholder_text'

  // state
  peopleViewCount = peopleViewCount || 3
  const chairsSorted = [...event?.chairs]
  const speakersSorted = [...event?.speakers]
  chairsSorted?.sort((a, b) => a.sort_order - b.sort_order)
  speakersSorted?.sort((a, b) => a.sort_order - b.sort_order)
  return (
        <>
            {
                chair === 'text' &&
                !!event?.chairs.length &&
                <TextViewAgenda title={t('pages.eventagendapage.chairs')}
                                people={chairsSorted}
                                count={peopleViewCount}
                                activeAgenda={event?.agendaActive}/>
            }
            {
                speakerView === 'text' &&
                !!event?.speakers.length &&
                <TextViewAgenda title={t('pages.eventagendapage.speakers')}
                                people={speakersSorted}
                                count={peopleViewCount}
                                activeAgenda={event?.agendaActive}/>
            }
            {
                chair === 'avatar_text' &&
                !!event.chairs.length &&
                <AvatarTextViewAgenda title={t('pages.eventagendapage.chairs')}
                                      people={chairsSorted}
                                      count={peopleViewCount}
                                      activeAgenda={event?.agendaActive}/>
            } {
            speakerView === 'avatar_text' &&
            !!event?.speakers.length &&
            <AvatarTextViewAgenda title={t('pages.eventagendapage.speakers')}
                                  people={speakersSorted}
                                  count={peopleViewCount}
                                  activeAgenda={event?.agendaActive}/>
        }
            {
                chair === 'avatar' && !!event.chairs.length &&
                <AvatarViewAgenda title={t('pages.eventagendapage.chairs')}
                                  people={chairsSorted}
                                  count={peopleViewCount}
                                  activeAgenda={event?.agendaActive}/>

            } {
            speakerView === 'avatar' && !!event?.speakers.length &&
            <AvatarViewAgenda title={t('pages.eventagendapage.speakers')}
                              people={speakersSorted}
                              count={peopleViewCount}
                              activeAgenda={event?.agendaActive}/>

        }
            {!!event?.chairs?.length &&
                chair === 'placeholder_text' &&
                <PlaceholderTextViewAgenda
                    title={t('pages.eventagendapage.chairs')}
                    people={chairsSorted}
                    count={peopleViewCount}
                    activeAgenda={event?.agendaActive}/>
            }
            {!!event?.speakers?.length &&
                speakerView === 'placeholder_text' &&
                <PlaceholderTextViewAgenda
                    title={t('pages.eventagendapage.speakers')}
                    people={speakersSorted}
                    count={peopleViewCount}
                    activeAgenda={event?.agendaActive}/>
            }
        </>
  )
}

export default SpeakersAndChairsAgenda

import React from 'react'
import colors from '../../Colors.scss'

const ChatIcon = ({ width = '30', height = '30', color = colors.color_sidebar_icon }) => {
  return (
     <svg
            width={width}
            height={height}
            viewBox="0 0 141.73 141.73"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
        <path
          d="M94.03,73.81H66.87 L43.25,90.13V73.81H25.51V29.39h68.52V73.81z M105.37,75.2V28.01c0-5.49-4.54-9.96-10.11-9.96H24.27c-5.57,0-10.1,4.47-10.1,9.96 V75.2c0,5.49,4.54,9.96,10.1,9.96h7.64v26.58L70.4,85.16h24.86C100.83,85.16,105.37,80.68,105.37,75.2 M127.55,91.03V43.85 c0-5.5-4.54-9.96-10.12-9.96h-7.56v11.34h6.34v44.41H98.46v11.29L83.88,89.64H71.94l-15.81,11.34h23.88l29.79,23.04v-23.04h7.63 C123.02,100.99,127.55,96.52,127.55,91.03"
          fill={color}
        />
        </svg>

  )
}

export default ChatIcon

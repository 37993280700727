import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import { useLocation, useNavigate } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import { useDispatch, useSelector } from 'react-redux'

// src
import './Participants.scss'
import SearchIcon from '../../SVG/global/SearchIcon'
import { debounce } from '../../helper/Debounce'
import Presence from '../../components/Presence/Presence'
import colors from '../../Colors.scss'
// import ParticipantsSvg from '../../SVG/pages/participant/ParcipantsSvg'
// import Questions from '../../SVG/pages/question/Questions'
// custom SFS
import ParticipantsIcon from '../../SVG/custom/ParticipantsIcon'
import QuestionsIcon from '../../SVG/custom/QuestionsIcon'
import ContextHelp from '../../components/ContextHelp/ContextHelp'
import { useGetEventAttendeesQuery } from '../../redux/api/participtantApi/participantApi'
import { TableRender } from './TableParticipant/TableParticipant'
import { handleScroll } from '../../helper/ScrollHelper/LoadDataFromBotomOfDiv'
import { addSearch } from '../../redux/slices/participantSlice/participantSlice'
import Flags from './Flags'
import Avatar from '../../components/Avatar/Avatar'
import { useGetVisibleDetailsQuery } from '../../redux/api/agendaApi/agendaApi'
import { addSortParticipant } from '../../redux/slices/globalValue/globalValue'
import { useGlobalValue } from '../../hooks/redux/reduxHooks'

const updateParticipants = process.env.REACT_APP_TIME_FOR_UPDATE_PARTICIPANTS_S

export default function Participants () {
  // hooks
  const navigate = useNavigate()
  const inputRef = useRef(null)
  const participantsScroll = useRef(null)
  const dispatch = useDispatch()
  const location = useLocation()
  const scrollInfo = location.state
  // custom hooks
  const { eventId, participantSorting: orderBy, slug } = useGlobalValue()
  // reducer
  const searchValue = useSelector(state => state.participantReducer.searchValue)
  const timeReq = useSelector(state => state.participantReducer.timeReq)

  const { t, i18n } = useTranslation()

  // state
  const [offset, setOffset] = useState(0)
  const [participants, setParticipants] = useState([])
  const [participantsCount, setParticipantsCount] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)
  const [show, setShow] = useState(false)
  const [order, setOrder] = useState('')
  const [search, setSearch] = useState(searchValue)
  const [tags, setTags] = useState()
  const [scrollLoader, setScrollLoader] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [loader, setLoader] = useState(false)
  const [timePoll, setTimePoll] = useState(0)
  const [scrollLoaderFromParticipant, setScrollLoaderFromParticipant] = useState(false)
  // query
  const { data: attendeesQuery, refetch, isLoading, isFetching } = useGetEventAttendeesQuery({
    eventId,
    orderBy,
    order,
    searchValue,
    limit: 50,
    offset
  },
  {
    pollingInterval: timePoll
  })
  const [showModalHelper, setShowModalHelper] = useState({
    open: false,
    event: {}
  })
  const { data: allowedProperty } = useGetVisibleDetailsQuery(eventId)

  useEffect(() => {
    const time = +(+(timeReq + +(updateParticipants + '000')) - new Date().getTime())
    if (time <= 0) {
      refetch()
      setTimePoll(+(updateParticipants + '000'))
    } else {
      setTimePoll(+time)
    }
  }, [timeReq])

  useEffect(() => {
    if (scrollInfo?.lastScroll && participantsScroll?.current !== null) {
      setScrollLoaderFromParticipant(true)
      setParticipants(scrollInfo?.participants)
      setOffset(scrollInfo?.offset)
      setTimeout(() => {
        participantsScroll.current.scrollTop = scrollInfo?.lastScroll
        setScrollLoaderFromParticipant(false)
      })
    }
    window.addEventListener('beforeunload', () => {
      navigate(location.pathname, {})
    })
  }, [scrollInfo?.lastScroll, participantsScroll?.current])

  const getSortedAttendeesAPI = async () => {
    setParticipantsCount(attendeesQuery?.meta?.total_count)
    const notDublicatePerson = Array.from(new Set([...participants, ...attendeesQuery.items]))
    const uniqueObjArray = [
      ...new Map(notDublicatePerson.map((item) => [item.people.id, item])).values()
    ]
    if (refresh) {
      setLoader(true)
      setParticipants(attendeesQuery.items)
      setOffset(0)
      setRefresh(false)
      setScrollLoader(false)
    } else {
      setParticipants(uniqueObjArray)
    }
    setLoader(false)
  }

  useEffect(() => {
    if (attendeesQuery && !participants.length) {
      getSortedAttendeesAPI()
    } else if (!isLoading && !isFetching) {
      getSortedAttendeesAPI()
    }
  }, [attendeesQuery])

  const handleFilterMenu = (e) => {
    const isAsc = orderBy === e.label && order === ''
    setOrder(isAsc ? '-' : '')
    dispatch(addSortParticipant(e.label))
    setRefresh(true)
    setLoader(true)
  }

  const updateSearchValue = useCallback(
    debounce((value) => {
      setRefresh(true)
      setParticipants([])
      setOffset(0)
      setLoader(true)
      dispatch(addSearch(value))
    }, 1000)
    , [])
  const handleSearch = async (e) => {
    setSearch(e.target.value)
    if (!e.target.value) {
      setParticipants([])
    }
    updateSearchValue(e.target.value)
  }
  return (

        <>
            <TableRender show={show} setShow={setShow}/>
            <div className="participants-main">
                <div className="participants-inside">
                    <div className="title-main">
                        <div className="title">
                            <ParticipantsIcon color={colors.color_sidebar_icon} width='30' height='30'/>
                            <p>
                                <span className="help_span"
                                      onClick={(e) => {
                                        setShowModalHelper({
                                          open: !showModalHelper.open,
                                          event: e.currentTarget
                                        })
                                      }}>
                                {/* <QuestionsIcon color={colors.color_sidebar_icon} width='20' height='20'/> */}
                                    {t('sidebar.participants')}
                                    {showModalHelper && <ContextHelp helpText={{
                                      id: 2,
                                      what: t('pages.participantspage.context-help-paticipants')
                                    }} open={showModalHelper.open}
                                         anchorEl={showModalHelper.event}
                                         setShowModalHelper={setShowModalHelper}/>}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="search">
                        <TextField
                            sx={{
                              '& label': { paddingLeft: (theme) => theme.spacing(1) },
                              '& input': {
                                paddingLeft: (theme) => theme.spacing(1),
                                zIndex: '2',
                                fontFamily: colors.font_main,
                                fontWeight: '500',
                                fontSize: '14px',
                                lineHeight: '40px'

                              },
                              '& fieldset': {
                                paddingLeft: (theme) => theme.spacing(2),
                                borderRadius: '30px',
                                bgcolor: colors.bgColor_input_participant

                              },
                              width: '100%'
                            }}
                            size="small"
                            margin="normal"
                            type="text"
                            ref={inputRef}
                            value={search}
                            placeholder={t('pages.participantspage.search-participant')}
                            onChange={(e) => {
                              handleSearch(e)
                            }}

                            InputProps={{
                              startAdornment: (
                                    <InputAdornment position="end" sx={{ zIndex: '2' }}>
                                        <SearchIcon/>
                                    </InputAdornment>
                              ),
                              endAdornment: (
                                    <InputAdornment position="end" sx={{ zIndex: '2' }}>
                                        <div className='participants-helper-mobile'
                                             onClick={(e) => {
                                               setShowModalHelper({
                                                 open: !showModalHelper.open,
                                                 event: e.currentTarget
                                               })
                                             }}>
                                            <QuestionsIcon height='25' width='25'
                                                           color={colors.color_grey_shade}/>
                                            {showModalHelper && <ContextHelp helpText={{
                                              id: 2,
                                              what: t('pages.participantspage.context-help-paticipants')
                                            }} open={showModalHelper.open}
                                                 anchorEl={showModalHelper.event}
                                                 setShowModalHelper={setShowModalHelper}/>}
                                        </div>
                                    </InputAdornment>

                              )

                            }}
                            variant="outlined"
                        />

                        {/* <Popover */}
                        {/*    id={anchorEl ? 'simple-popover' : undefined} */}
                        {/*    open={Boolean(anchorEl)} */}
                        {/*    anchorEl={anchorEl} */}
                        {/*    onClose={() => setAnchorEl(null)} */}
                        {/*    anchorOrigin={{ */}
                        {/*      vertical: 'bottom', */}
                        {/*      horizontal: 'left' */}
                        {/*    }} */}
                        {/* > */}

                        {/*    <p className="filter-menu-head"><FormattedMessage id="sort-by" defaultMessage="Sort by"/> */}
                        {/*    </p> */}
                        {/*    {fillterMenu.map((menu, index) => ( */}
                        {/*        <p key={index} */}
                        {/*           className="filter-menu" */}
                        {/*           onClick={() => { */}
                        {/*             handleFilterMenu(menu) */}
                        {/*             setAnchorEl(null) */}
                        {/*             setOffset(0) */}
                        {/*           }} */}
                        {/*        > */}
                        {/*            {menu.name} {menu.label === orderBy && <CheckIcon/>} */}
                        {/*        </p> */}
                        {/*    ))} */}

                        {/* </Popover> */}
                        {/* <div className="orderBy__container"> */}
                        {/*    <div className="participants-filter" */}
                        {/*         style={{ */}
                        {/*           marginTop: '6px' */}
                        {/*         }}> */}

                        {/*        <FilterListIcon */}
                        {/*            onClick={(e) => setAnchorEl(e.currentTarget)} */}
                        {/*            fontSize="medium" */}
                        {/*        /> */}

                        {/*        { */}
                        {/*            order === '' */}
                        {/*              ? <ArrowDownwardIcon */}
                        {/*                    onClick={() => { */}
                        {/*                      setOrder('-') */}
                        {/*                      setRefresh(true) */}
                        {/*                      setOffset(0) */}
                        {/*                      setLoader(true) */}
                        {/*                    }}/> */}
                        {/*              : <ArrowUpwardIcon */}
                        {/*                    onClick={() => { */}
                        {/*                      setOrder('') */}
                        {/*                      setRefresh(true) */}
                        {/*                      setOffset(0) */}
                        {/*                      setLoader(true) */}
                        {/*                    }}/> */}
                        {/*        } */}
                        {/*    </div> */}
                        {/* </div> */}

                        {/* <img */}
                        {/*    src={fullScreen} */}
                        {/*    className="fullScreen-icon" */}
                        {/*    onClick={() => { */}
                        {/*      setShow(!show) */}
                        {/*    }} */}
                        {/* /> */}

                    </div>
                    {loader ? <CircularProgress className='circularProgress'/> : <div
                        className="participants-cards-main"
                        ref={participantsScroll}
                        onScroll={(e) => {
                          !isFetching && !isLoading && handleScroll({
                            e,
                            arr: participants,
                            setOffset,
                            offset,
                            itemsCount: participantsCount,
                            setScrollLoader
                          })
                        }}
                    >
                        {scrollLoaderFromParticipant &&
                            <div className='participant__loader'><CircularProgress className='circularProgress'/></div>}
                        <Paper elevation={0}>
                            <div className="participant-profile-tag">
                                {tags &&
                                    tags.map((tag) => (
                                        <div className="tag" key={tag.id}>
                                            <span>{tag.name}</span>
                                            <p
                                                fontSize="small"
                                                onClick={() =>
                                                  setTags(tags?.filter((item) => item.id !== tag.id))
                                                }
                                            >{t('pages.participantspage.close')}</p>
                                        </div>
                                    ))}
                            </div>
                        </Paper>
                        {/* {loader && <CircularProgress className='circularProgress'/>} */}
                        {!show &&
                            participants !== undefined &&
                            participants.length !== 0 && (
                        // <TableRender headCells={headCells} show={false}/>
                          participants.map((participant) => (
                                    <Paper elevation={0}
                                           key={participant?.people?.id}>
                                        <div className="participants-card">
                                            <div
                                                onClick={() =>
                                                  navigate(
                                                        `/events/${eventId}/${slug}/participants/profile?profileId=${participant.people.id}`,
                                                        {
                                                          state: {
                                                            profileId: participant.people.id,
                                                            scrollInfo: {
                                                              participants,
                                                              offset,
                                                              lastScroll: participantsScroll.current.scrollTop
                                                            }
                                                          }
                                                        }
                                                  )
                                                }
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {allowedProperty?.avatar_enabled
                                                  ? <Avatar url={participant?.people?.avatar?.download_url}
                                                              status_online={participant?.people?.status_online === 'ONLINE'}
                                                              id={participant?.people?.id}
                                                              name={participant?.people?.full_name}
                                                    /> : ''}
                                                <div className="participants-detail"
                                                >
                                                    {allowedProperty?.full_name_enabled ? <div className="user-name">
                                                        {participant?.people?.full_name ?? ''}
                                                        <Presence status={participant?.people?.attendee_type}/>
                                                    </div> : ''}
                                                    {allowedProperty?.email_enabled ? <p className="user-email">
                                                        {participant?.people?.email && participant?.people?.email}
                                                    </p> : ''}
                                                    <p className="user__full-tile">

                                                        <Flags abbreviation={participant?.people?.country}
                                                               show={allowedProperty?.country_flag_enabled}/>

                                                        {participant?.people?.full_title && participant?.people?.full_title}
                                                    </p>
                                                </div>
                                            </div>
                                            {/* <div className="cursor-pointer" */}
                                            {/*     onClick={() => */}
                                            {/*       navigate(`/events/${eventId}/${slug}/participants/chat/${participant.people.id}`) */}
                                            {/*     } */}
                                            {/* > */}
                                            {/*    <svg */}
                                            {/*        width="24" */}
                                            {/*        height="24" */}
                                            {/*        viewBox="0 0 24 24" */}
                                            {/*        fill="none" */}
                                            {/*        xmlns="http://www.w3.org/2000/svg" */}
                                            {/*    > */}
                                            {/*        <path */}
                                            {/*            fillRule="evenodd" */}
                                            {/*            clipRule="evenodd" */}
                                            {/*            d="M0 12.018C0 5.69654 5.052 0 12.024 0C18.84 0 24 5.58839 24 11.982C24 19.397 17.952 24 12 24C10.032 24 7.848 23.4712 6.096 22.4376C5.484 22.0651 4.968 21.7886 4.308 22.005L1.884 22.7261C1.272 22.9183 0.72 22.4376 0.9 21.7886L1.704 19.0967C1.836 18.7241 1.812 18.3275 1.62 18.015C0.588 16.1161 0 14.037 0 12.018ZM10.44 12.018C10.44 12.8713 11.124 13.5563 11.976 13.5684C12.828 13.5684 13.512 12.8713 13.512 12.03C13.512 11.1768 12.828 10.4917 11.976 10.4917C11.136 10.4797 10.44 11.1768 10.44 12.018ZM15.972 12.03C15.972 12.8713 16.656 13.5684 17.508 13.5684C18.36 13.5684 19.044 12.8713 19.044 12.03C19.044 11.1768 18.36 10.4917 17.508 10.4917C16.656 10.4917 15.972 11.1768 15.972 12.03ZM6.444 13.5684C5.604 13.5684 4.908 12.8713 4.908 12.03C4.908 11.1768 5.592 10.4917 6.444 10.4917C7.296 10.4917 7.98 11.1768 7.98 12.03C7.98 12.8713 7.296 13.5563 6.444 13.5684Z" */}
                                            {/*            fill={colors.color_sidebar_icon} */}
                                            {/*        /> */}
                                            {/*    </svg> */}
                                            {/* </div> */}
                                        </div>
                                    </Paper>
                          )))}
                        {isLoading || isFetching || scrollLoader ? <CircularProgress/> : ''}
                    </div>}

                </div>
            </div>
        </>
  )
}

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PlaceholderText from './SpeakerAndChairs/Placeholder_text'
import AvatarText from './SpeakerAndChairs/Avatar_text'
import AvatarView from './SpeakerAndChairs/AvatarView'
import TextView from './SpeakerAndChairs/TextView'

const SpeakersAndChairsDetail = ({ agendaDetail, slug, eventId }) => {
  const { t, i18n } = useTranslation()
  const speakerView = process.env.REACT_APP_SPEAKER_VIEW
  const chair = process.env.REACT_APP_CHAIRS_VIEW
  const peopleViewCount = process.env.REACT_APP_PEOPLE_COUNT_ON_AGENDA_DETAILS
  // state
  const [viewAllCount, setViewAllCount] = useState(peopleViewCount || 5)
  const [viewAllCountChair, setViewAllCountChair] = useState(peopleViewCount || 5)

  const chairsSorted = agendaDetail?.chairs ? [...agendaDetail?.chairs] : []
  const speakersSorted = agendaDetail?.speakers ? [...agendaDetail?.speakers] : []
  chairsSorted?.sort((a, b) => a.sort_order - b.sort_order)
  speakersSorted?.sort((a, b) => a.sort_order - b.sort_order)

  return (
        <>
            {chairsSorted.length > 0 &&
                chair === 'placeholder_text' &&
                <PlaceholderText
                    slug={slug}
                    viewAllCount={viewAllCountChair}
                    title={t('pages.eventagendapage.chair')}
                    setViewAllCount={setViewAllCountChair}
                    eventId={eventId}
                    people={chairsSorted}
                />
            }
            {speakersSorted.length > 0 &&
                speakerView === 'placeholder_text' &&
                <PlaceholderText
                    slug={slug}
                    viewAllCount={viewAllCount}
                    title={t('pages.eventagendapage.speakers')}
                    setViewAllCount={setViewAllCount}
                    eventId={eventId}
                    people={speakersSorted}
                />
            }
            {
                chair === 'avatar_text' && chairsSorted?.length > 0 &&
                <AvatarText
                    viewAllCount={viewAllCountChair}
                    title={t('pages.eventagendapage.chair')}
                    setViewAllCount={setViewAllCountChair}
                    eventId={eventId}
                    people={chairsSorted}
                    slug={slug}

                />
            }
            {speakerView === 'avatar_text' && speakersSorted.length > 0 &&
                <AvatarText
                    viewAllCount={viewAllCount}
                    title={t('pages.eventagendapage.speakers')}
                    setViewAllCount={setViewAllCount}
                    eventId={eventId}
                    people={speakersSorted}
                    slug={slug}

                />
            }
            {
                chair === 'avatar' && chairsSorted?.length > 0 &&
                <AvatarView
                    viewAllCount={viewAllCountChair}
                    title={t('pages.eventagendapage.chair')}
                    people={chairsSorted}
                    slug={slug}
                    eventId={eventId}
                    setViewAllCount={setViewAllCountChair}
                />

            }
            {
                speakerView === 'avatar' && speakersSorted.length > 0 &&
                <AvatarView
                    viewAllCount={viewAllCount}
                    title={t('pages.eventagendapage.speakers')}
                    people={speakersSorted}
                    slug={slug}
                    eventId={eventId}
                    setViewAllCount={setViewAllCount}
                />

            }
            {chair === 'text' && chairsSorted?.length > 0 &&
                <TextView
                    setViewAllCount={setViewAllCountChair}
                    eventId={eventId}
                    people={chairsSorted}
                    slug={slug}
                    viewAllCount={viewAllCountChair}
                    title={t('pages.eventagendapage.chair')}
                />
            }
            {speakersSorted.length > 0 && speakerView === 'text' &&
                <TextView
                    setViewAllCount={setViewAllCount}
                    eventId={eventId}
                    people={speakersSorted}
                    slug={slug}
                    viewAllCount={viewAllCount}
                    title={t('pages.eventagendapage.speakers')}
                />
            }

        </>
  )
}

export default SpeakersAndChairsDetail

// eslint-disable-next-line no-unused-vars
import * as React from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import colors from '../../Colors.scss'

export const AsqBtn = styled(Button, {
  shouldForwardProp: (propName) => propName !== 'fullWidth'
})(({ theme }) => ({
  // background: 'rgba(161, 169, 191, 0.2)',
  // color: colors.color_black,
  color: colors.button_enabled_text_color,
  backgroundColor: colors.button_enabled_color,
  '&:hover': {
    backgroundColor: colors.color_hover,
    color: colors.color_hover_text
  },
  fontFamily: colors.font_main,
  padding: '16px 32px',
  fontWeight: '700',
  wight: '100%',
  minWidth: '100%',
  cursor: 'pointer',
  textTransform: 'inherit'
}))

export const GradiantBtn = styled(Button, {
  shouldForwardProp: (propName) => propName !== 'fullWidth' && propName !== 'loading'
})(({ theme }) => ({
  // background: 'linear-gradient(90.98deg, #ff68c3 0%, #ff7474 29.69%, #5771f9 100%)',
  // color: colors.color_white,
  // minWight: '100%',
  // // backgroundColor: colors.color_gradient,
  // '&:hover': {
  //   backgroundColor: colors.color_hover,
  //   color: colors.color_black
  // },
  color: colors.button_enabled_text_color,
  backgroundColor: colors.button_enabled_color,
  '&:hover': {
    backgroundColor: colors.color_hover,
    color: colors.color_hover_text
  },
  minWight: '100%',
  fontFamily: colors.font_main,
  padding: '16px 32px',
  borderRadius: '8px',
  fontWeight: '700',
  cursor: 'pointer',
  textTransform: 'inherit'

}))
export const LoginBtn = styled(Button, {
  shouldForwardProp: (propName) => propName !== 'fullWidth'
})(({ theme }) => ({
  color: colors.button_enabled_text_color,
  backgroundColor: colors.button_enabled_color,
  '&:hover': {
    backgroundColor: colors.color_hover,
    color: colors.color_hover_text
  },
  fontFamily: colors.font_main,
  padding: '16px 32px',
  fontWeight: '700',
  // wight: '100%',
  // minWidth: '100%',
  cursor: 'pointer',
  textTransform: 'inherit'
}))

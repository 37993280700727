import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import parse from 'html-react-parser'
import { useDispatch } from 'react-redux'

// Intl
import { useTranslation } from 'react-i18next'

// Mui component
import CircularProgress from '@mui/material/CircularProgress'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'

// Styles
import './EventAgendaDetail.scss'
import colors from '../../../Colors.scss'

// SVG
// import ArrowPlayer from '../../../SVG/global/ArrowPlayer'
import ArrowPlayer from '../../../SVG/custom/ArrowPlayer'
import IsOnlineAgenda from '../../../SVG/global/isOnlineAgenda'

// Local component
import SpeakersAndChairsDetail from './SpeakersAndChairsDetail'
import Attachments from '../../../components/Attachments/Attachments'
import SponsorAgenda from '../components/SponsorAgenda'
import AsqBtnComponent from '../../../components/Buttons/AsqBtn/AsqBtn'
import { GradiantBtn } from '../../../assets/muiStyles/Button-style'

// Redux
import {
  addEventFromVideo,
  addNavigateToAgenda,
  addUrlFromVideo,
  removeEventFromVideo
} from '../../../redux/slices/globalValue/globalValue'
import { useGetAgendaDetailsQuery } from '../../../redux/api/agendaApi/agendaApi'

// Actions
import { DayTranslate, MonthTranslate } from '../../../helper/TranslateHelper/DataComponents'
import { toDateString } from '../../../helper/DateHelper/ToDateString'
import { useGlobalValue } from '../../../hooks/redux/reduxHooks'
import AgendaIcon from '../../../SVG/custom/AgendaIcon'

export default function EventAgendaDetail () {
  // hooks
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { t, i18n } = useTranslation()

  // url
  const url = location.pathname.split('/')
  const IdEventDesc = location.pathname.split('/').slice(-3)[0]
  const agendaId = location.pathname.split('/').slice(-3)[0]

  // redux
  const { slug, eventId, agendaActive, navigateToAgenda } = useGlobalValue()

  // state
  const [agendaMassage, setAgendaMassage] = useState('')

  // query
  const { data: agendaDetail, isLoading, isFetching } = useGetAgendaDetailsQuery({ eventId, agendaId })

  const dayTranslate = toDateString(agendaDetail?.day)?.day
  const monthTranslate = toDateString(agendaDetail?.day)?.month

  useEffect(() => {
    if (agendaDetail?.body[0]?.value) {
      setAgendaMassage(parse(agendaDetail?.body[0]?.value))
    }
  }, [agendaDetail?.body[0]?.value])

  return (
      <div className="event-detail-main">
        {isLoading || isFetching && <CircularProgress className='circularProgress'/>}
        {!isLoading && !isFetching && (
            <>
              <div>
                <p className="event-detail-title">
                    <ArrowBackOutlinedIcon className="cursor-pointer arrow" onClick={() => {
                      if (url.includes('video') && !navigateToAgenda) {
                        navigate(-2)
                        dispatch(addNavigateToAgenda(''))
                      } else {
                        navigate(-1)
                        dispatch(addNavigateToAgenda(''))
                      }
                    }}/>
                    <span>{t('pages.eventagendapage.agenda-details')}</span>
                </p>
              </div>
              <div className="event-detail-inside">
                <div className="agenda-detail-title">
                    {agendaDetail?.title && <h2> {agendaDetail?.title}</h2>}
                    {agendaActive === 'true' && <IsOnlineAgenda width='100'/>}
                </div>
                {agendaDetail?.sponsor && <SponsorAgenda event={agendaDetail}/>}
                <div className="agenda-detail-time-container">
                    <AgendaIcon width='56' height='56' color={colors.color_sidebar_icon}/>
                    <div className="agenda-detail-time">
                        <div className="agenda-detail-date">
                          <DayTranslate day={dayTranslate} short={false}/>, {' '}
                          <MonthTranslate month={monthTranslate}/> {monthTranslate?.split(' ')[1]}
                        </div>

                        <p className="agenda-detail-date-time">
                            <b>{agendaDetail?.start.split(':')[0] + ':' +
                                agendaDetail?.start.split(':')[1]} -
                                {agendaDetail?.end.split(':')[0] +
                                    ':' +
                                    agendaDetail?.end.split(':')[1]}</b>
                        </p>
                    </div>
                    {agendaDetail?.video && agendaDetail?.video.status !== 'NOT_AUTHORIZED' && !url.includes('video') && agendaActive !== 'true' &&
                    <GradiantBtn
                      onClick={() => {
                        dispatch(addUrlFromVideo({
                          urlFromVideo: {
                            videoUrlHls: agendaDetail?.video?.hls_url,
                            videoUrlEmbed: agendaDetail?.video?.embed_code
                          }
                        }))
                        if (agendaDetail?.speakers.length === 1) {
                          dispatch(addEventFromVideo(agendaDetail))
                        } else {
                          dispatch(removeEventFromVideo())
                        }
                        navigate(`/events/${eventId}/${slug}/${IdEventDesc}/video/`)
                      }}
                      className="agenda-detail-desc-watch-btn">
                      <ArrowPlayer color={colors.color_three}/><span>{t('pages.eventagendapage.watch')}</span>
                    </GradiantBtn>}
                  </div>

                  <SpeakersAndChairsDetail agendaDetail={agendaDetail} eventId={eventId} slug={slug}/>
                  {agendaMassage && <div className="agenda-detail-desc">
                      <p className="agenda-detail-desc-head">{t('pages.eventagendapage.description')}</p>
                      <div>{agendaMassage}</div>
                </div>}
                <div className="agenda-item-option">
                    {(IdEventDesc !== '38') && (agendaDetail?.speakers?.length > 0 || agendaDetail?.chairs?.length > 0 || agendaMassage) &&
                        <AsqBtnComponent id={IdEventDesc} referrer='agenda' question_type='question_speaker'/>}
                </div>
                <Attachments event={agendaDetail}/>

              </div>
            </>
        )}
        </div>
  )
}

import React from 'react'
import colors from '../../Colors.scss'

const AgendaIcon = ({ height = '30', width = '30', color = 'white', opacity = '1' }) => {
  return (
      <svg
            width={width}
            height={height}
            viewBox="0 0 141.7 141.7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M56.8,44h28.1V33.4H56.8V44z M102.8,33.4V44h10.6v60h-85V65.5H104V54.9H28.4V44H39V33.4H17.8l0,0V44v10.9v10.6 v49.1l0,0h10.6h85H124V33.4C124,33.4,102.8,33.4,102.8,33.4z M42.5,49.4h10.6V27H42.5V49.4z M88.5,49.4h10.6V27H88.5V49.4z"
                fill={color} fillOpacity={opacity}
            />
        </svg>
  )
}

export default AgendaIcon

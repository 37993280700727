import React from 'react'
import colors from '../../Colors.scss'

const ChatIcon = ({ width = '24', height = '24', color = colors.color_sidebar_icon }) => {
  return (
     <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
        <path
          fillRule="evenodd"
          clipRule="evenodd" d="M0 12.018C0 5.69654 5.052 0 12.024 0C18.84 0 24 5.58839 24 11.982C24 19.397 17.952 24 12 24C10.032 24 7.848 23.4712 6.096 22.4376C5.484 22.0651 4.968 21.7886 4.308 22.005L1.884 22.7261C1.272 22.9183 0.72 22.4376 0.9 21.7886L1.704 19.0967C1.836 18.7241 1.812 18.3275 1.62 18.015C0.588 16.1161 0 14.037 0 12.018ZM10.44 12.018C10.44 12.8713 11.124 13.5563 11.976 13.5684C12.828 13.5684 13.512 12.8713 13.512 12.03C13.512 11.1768 12.828 10.4917 11.976 10.4917C11.136 10.4797 10.44 11.1768 10.44 12.018ZM15.972 12.03C15.972 12.8713 16.656 13.5684 17.508 13.5684C18.36 13.5684 19.044 12.8713 19.044 12.03C19.044 11.1768 18.36 10.4917 17.508 10.4917C16.656 10.4917 15.972 11.1768 15.972 12.03ZM6.444 13.5684C5.604 13.5684 4.908 12.8713 4.908 12.03C4.908 11.1768 5.592 10.4917 6.444 10.4917C7.296 10.4917 7.98 11.1768 7.98 12.03C7.98 12.8713 7.296 13.5563 6.444 13.5684Z"
          fill={color}
        />
        </svg>

  )
}

export default ChatIcon

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField } from '@material-ui/core'
import { useLocation, useNavigate } from 'react-router-dom'
import setAuthToken from '../../auth/SetAuthToken'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import InputAdornment from '@mui/material/InputAdornment'
import { toast, ToastContainer } from 'react-toastify'
import Alert from '@mui/material/Alert'
import { useDispatch, useSelector } from 'react-redux'

// src
import './SignInPage.scss'
import { GradiantBtn } from '../../assets/muiStyles/Button-style'
import { addRefreshToken, addToken, addUserId } from '../../redux/slices/authSlice/authSlice'
import { usePostSignInMutation } from '../../redux/api/authApi/authApi'
import { addRefreshTokenExpiration } from '../../redux/slices/globalValue/globalValue'
import bg from '../../assets/cust/images/FS900609_012_2.jpg'

export default function SignInPage () {
  // hooks
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  // env
  const refresh_token_expiration = process.env.REACT_APP_REFRESH_TOKEN_EXPIRATION

  // redux
  const evSlug = useSelector(state => state.globalValueReducer.slug)
  const eventId = useSelector(state => state.globalValueReducer.eventId)
  const refresh_token_expirationRedux = useSelector(state => state.globalValueReducer.refresh_token_expiration)
  // state
  const [credientials, setCredientials] = useState({
    email: '',
    password: ''
  })
  // const [rememberMe, setRememberMe] = useState('false')
  const [errorText, setError] = useState('')
  const [passwordEye, setPasswordEye] = useState(false)
  const locationEdit = location.pathname.split('/')
  // query
  const [postSignIn, isLoading] = usePostSignInMutation(credientials)
  useEffect(() => {
    if (window.location.href.includes('/sign-in') || window.location.href.includes('/user/editing-profile/') && refresh_token_expirationRedux === 'expired') {
      refresh_token_expirationRedux && toast(t('pages.signinpage.toast-your-session-has-expired'), {
        toastId: 'session-expired-message'
      })
    }
  }, [])
  // useEffect(() => {
  //   error && toast.error(<FormattedMessage id="toast-error-invalid-credentials"
  //                                   defaultMessage="invalid credentials!"/>, {
  //     position: 'top-center'
  //   })
  // }, [error])
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (credientials.email.length <= 0 || credientials.password.length <= 0) {
      return toast.error(t('pages.signinpage.toast-fields-required'), {
        position: 'top-center'
      })
    }
    postSignIn(credientials)
      .unwrap()
      .then((response) => {
        if (response && response.access_token) {
          dispatch(addToken(response.access_token))
          dispatch(addRefreshToken(response.refresh_token))
          dispatch(addUserId(response.user.pk))
          dispatch(addRefreshTokenExpiration(+new Date() + +refresh_token_expiration))
          setAuthToken(response.access_token)
          if (locationEdit[2] === 'editing-profile') {
            navigate('/user/editing-profile/')
          } else {
            // eventId
            //   ? navigate('/events/' + eventId + '/' + evSlug + '/agenda/')
            //   :
            navigate('/')
          }
        }
      })
      .catch(() => {
        toast.error(t('pages.signinpage.toast-invalid-credentials'), {
          position: 'top-center'
        })
      })
  }
  const handleChange = (e) => {
    setCredientials({ ...credientials, [e.target.name]: e.target.value })
  }

  return (
        <>
            <ToastContainer/>
            <div className="sign-in-main" style={{ position: 'relative' }}>
                <img src={bg}
                     style={{ width: '100%', height: '100%', position: 'absolute', top: '0', left: '0', zIndex: '0' }}
                     alt=""/>
                <form onSubmit={handleSubmit} noValidate>
                    <div className="sign-in-form" style={{ zIndex: '11', position: 'relative' }}>
                        <p className="welcome-msg">{t('pages.signinpage.welcome')}</p>
                        <p className="desc">{t('pages.signinpage.enter-your-credentials')}</p>

                        <TextField
                            style={{ marginTop: '50px' }}
                            error={!!errorText}
                            // helperText= 'Please Enter Your Email'
                            value={credientials.email}
                            onFocus={() => setError('')}
                            onChange={(e) => handleChange(e)}
                            placeholder={t('pages.signinpage.placeholder-enter-your-email-address')}
                            type="email"
                            name="email"
                            variant="outlined"
                            required
                        />

                        <TextField
                            style={{ marginTop: '24px', borderRadius: '8px' }}
                            error={!!errorText}
                            onFocus={() => setError('')}
                            // helperText= 'Please Enter Your Email'
                            value={credientials.password}
                            onChange={(e) => handleChange(e)}
                            name="password"
                            // inputProps={{ pattern: "^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$"}}
                            placeholder={t('pages.signinpage.placeholder-enter-your-password')}
                            type={!passwordEye ? 'password' : 'text'}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                    <InputAdornment position="end">
                                        {passwordEye
                                          ? (
                                                <VisibilityIcon
                                                    onClick={() => setPasswordEye(!passwordEye)}
                                                />
                                            )
                                          : (
                                                <VisibilityOffIcon
                                                    onClick={() => setPasswordEye(!passwordEye)}
                                                />
                                            )}
                                    </InputAdornment>
                              )
                            }}
                            required
                        />

                        <br/>
                        {errorText
                          ? (
                                <Alert
                                    className="error-message"
                                    style={{}}
                                    variant="outlined"
                                    severity="error"
                                >
                                    {errorText}
                                </Alert>
                            )
                          : (
                              ''
                            )}
                        <div className="sign-in-option">
                            {/* <p */}
                            {/*    className="forgot-pass" */}
                            {/*    onClick={() => navigate('/user/register')} */}
                            {/* > */}
                            {/*    <FormattedMessage id="register-me" defaultMessage="Not yet registered"/>? */}

                            {/* </p> */}

                            <p
                                className="forgot-pass"
                                onClick={() => {
                                  navigate('/password/forgot')
                                }}
                            >
                                {t('pages.signinpage.forgot-password')}
                            </p>
                        </div>
                        <GradiantBtn
                            type="submit"
                            loading={isLoading}
                            variant="contained"
                        >
                            {t('pages.signinpage.sign-in')}
                        </GradiantBtn>
                    </div>
                    <br/>
                </form>
                {/* <div className="details-container"> */}
                {/*    <div className="homepage-section-2-text"> */}
                {/*        <p className="heading">SFS Streaming</p> */}
                {/*        <p className="para">SFS uses its know-how and innovative spirit to manufacture high-performance components to meet customer-specific requirements.</p> */}
                {/*    </div> */}
                {/* </div> */}
            </div>
        </>
  )
}
